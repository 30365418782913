import { useContext, useEffect, useState } from 'react'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'
import { EnergyClass } from '../../../types'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box
} from '@mui/material'
import SelectInputLabel from '../../forms/SelectInputLabel'
import TextField from '../../forms/TextField'
import Spinner from '../../Spinner'
import { getRecordById } from '../../../helpers/utility'
import Fade from './Fade'
import Label from '../../typography/Label'
import WizardFormItem from './WizardFormItem'
import DisabledInput from '../../forms/DisabledInput'

const EnergyClassDetails = ({ pageId }: { pageId: number }) => {
  const { setters, data, currentStep } = useContext(ProjectWizardContext)

  const [currentEnergyClassId, setCurrentEnergyClassId] = useState<string>('')

  const [heatingSystemConstructedIn, setHeatingSystemConstructedIn] =
    useState<string>('1900')

  const [coolingSystemConstructedIn, setCoolingSystemConstructedIn] =
    useState<string>('1900')

  const [energyClasses, energyClassesLoading] = useFetchResource<
    IndexResponse<EnergyClass>
  >(
    `/partner_countries/${data.user?.partner_country_id}/energy_classes`,
    [data.user],
    !data.user
  )
  const setAllowNext = () => {
    setters.setAllowNext(currentEnergyClassId.length >= 1)
  }

  useEffect(() => {
    setHeatingSystemConstructedIn(
      data.project?.heating_system_constructed_in || '1900'
    )

    setCoolingSystemConstructedIn(
      data.project?.cooling_system_constructed_in || '1900'
    )

    setCurrentEnergyClassId(
      data.project?.current_energy_class?.id.toString() || ''
    )
  }, [])

  useEffect(() => {
    currentStep === pageId && setAllowNext()
  }, [currentStep])

  useEffect(() => {
    setters.setProject({
      ...data.project,
      current_energy_class:
        getRecordById(energyClasses?.data, currentEnergyClassId) ||
        data.project?.current_energy_class,
      heating_system_constructed_in: heatingSystemConstructedIn,
      cooling_system_constructed_in: coolingSystemConstructedIn
    })

    setAllowNext()
  }, [
    currentEnergyClassId,
    coolingSystemConstructedIn,
    heatingSystemConstructedIn
  ])

  return (
    <>
      <Typography variant='h5' marginTop={3} display={'block'}>
        Energy class details
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'heating_system_constructed_in'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <TextField
                    onChange={(e) =>
                      setHeatingSystemConstructedIn(e.target.value)
                    }
                    value={heatingSystemConstructedIn}
                    name={'heating_system_constructed_in'}
                    error={error}
                    type={'number'}
                    label={'Heating system constructed in'}
                  />
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Label>Heating system constructed in</Label>
                  <DisabledInput
                    name={'heating_system_constructed_in'}
                    value={data.project?.heating_system_constructed_in}
                  />
                </Box>
              </Fade>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'cooling_system_constructed_in'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <TextField
                    onChange={(e) =>
                      setCoolingSystemConstructedIn(e.target.value)
                    }
                    value={coolingSystemConstructedIn}
                    type={'number'}
                    name={'cooling_system_constructed_in'}
                    error={error}
                    label={'Cooling system constructed in'}
                  />
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Box>
                    <Label>Cooling system constructed in</Label>
                    <DisabledInput
                      name={'cooling_system_constructed_in'}
                      value={data.project?.cooling_system_constructed_in}
                    />
                  </Box>
                </Box>
              </Fade>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          {energyClassesLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'current_energy_class'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                    <SelectInputLabel id={'current-energy-class-label'}>
                      Current energy class *
                    </SelectInputLabel>
                    <Select
                      name={'current_energy_class_id'}
                      error={error}
                      variant={'outlined'}
                      labelId={'current-energy-class-label'}
                      value={currentEnergyClassId}
                      label={'Energy class'}
                      onChange={(e) =>
                        setCurrentEnergyClassId(e.target.value.toString())
                      }
                      required={true}
                    >
                      <MenuItem value={''}>None</MenuItem>
                      {energyClasses.data.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.identifier}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Current energy class</Label>
                    <DisabledInput
                      name={'current_energy_class_id'}
                      value={data.project?.current_energy_class?.id}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default EnergyClassDetails
