import React, { useContext, useEffect, useState } from 'react'
import TextField from '../../forms/TextField'
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  Input
} from '@mui/material'
import Fade from './Fade'
import SelectInputLabel from '../../forms/SelectInputLabel'
import Label from '../../typography/Label'
import DataText from '../../typography/DataText'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import { BuildingType, BuildingSubType } from '../../../types'
import Spinner from '../../Spinner'
import { TenureStatus } from '../../../types'
import { getRecordById } from '../../../helpers/utility'
import WizardFormItem from './WizardFormItem'
import DisabledInput from '../../forms/DisabledInput'

const BuildingDetails = ({ pageId }: { pageId: number }) => {
  const { currentStep, setters, data } = useContext(ProjectWizardContext)
  const { project } = data

  const [projectName, setProjectName] = useState<string>(project?.name || '')
  const [clientNumber, setClientNumber] = useState<string>(
    project?.client_number || ''
  )
  const [buildingTypeId, setBuildingTypeId] = useState<string>(
    project?.building_type?.id.toString() || ''
  )
  const [buildingSubTypeId, setBuildingSubTypeId] = useState<string>(
    project?.building_sub_type?.id.toString() || ''
  )
  const [tenureStatusId, setTenureStatusId] = useState<string>(
    project?.tenure_status?.id.toString() || ''
  )
  const [buildingConstructedIn, setBuildingConstructedIn] = useState<number>(
    project?.building_constructed_in || 1900
  )

  const [buildingTypes, buildingTypesLoading] =
    useFetchResource<IndexResponse<BuildingType>>('/building_types')

  const [buildingSubTypes] = useFetchResource<IndexResponse<BuildingSubType>>(
    `/building_types/${buildingTypeId}/building_sub_types`,
    [buildingTypeId],
    buildingTypeId === ''
  )

  const [tenureStatuses, tenureStatusesLoading] = useFetchResource<
    IndexResponse<TenureStatus>
  >(
    `/partner_countries/${data.user?.partner_country_id}/tenure_statuses`,
    [data.user],
    !data.user
  )

  const setAllowNext = () => {
    setters.setAllowNext(
      projectName.length >= 1 &&
        buildingTypeId.length >= 1 &&
        buildingSubTypeId.length >= 1
    )
  }

  useEffect(() => {
    currentStep === pageId && setAllowNext()
  }, [currentStep])

  useEffect(() => {
    setters.setProject({
      ...data.project,
      name: projectName,
      client_number: clientNumber,
      building_type:
        getRecordById(buildingTypes?.data, buildingTypeId) ||
        data.project?.building_type,
      building_sub_type:
        getRecordById(buildingSubTypes?.data, buildingSubTypeId) ||
        data.project?.building_sub_type,
      tenure_status:
        getRecordById(tenureStatuses?.data, tenureStatusId) ||
        data.project?.tenure_status,
      building_constructed_in: buildingConstructedIn
    })

    setAllowNext()
  }, [
    projectName,
    clientNumber,
    buildingTypeId,
    buildingSubTypeId,
    tenureStatusId,
    buildingConstructedIn
  ])

  return (
    <>
      <Typography variant='h5' marginTop={3} display={'block'}>
        Building details
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'name'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <TextField
                    onChange={(e) => setProjectName(e.target.value)}
                    value={projectName}
                    name={'name'}
                    error={error}
                    type={'text'}
                    label={'Name'}
                    required={true}
                  />
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Label>Name</Label>
                  <DisabledInput name={'name'} value={data.project?.name} />
                </Box>
              </Fade>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'client_number'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <TextField
                    onChange={(e) => setClientNumber(e.target.value)}
                    error={error}
                    value={clientNumber}
                    name={'client_number'}
                    type={'text'}
                    label={'Client number'}
                  />
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Label>Client number</Label>
                  <DisabledInput
                    name={'client_number'}
                    value={data.project?.client_number}
                  />
                </Box>
              </Fade>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          {buildingTypesLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'building_type'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <Box>
                    <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                      <SelectInputLabel id={'building-type-label'}>
                        Building Type *
                      </SelectInputLabel>
                      <Select
                        name={'building_type_id'}
                        error={error}
                        variant={'outlined'}
                        labelId={'building-type-label'}
                        value={buildingTypeId}
                        label={'Building Type *'}
                        required={true}
                        onChange={(e) =>
                          setBuildingTypeId(e.target.value.toString())
                        }
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {buildingTypes.data.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Building Type</Label>
                    <DataText>{data.project?.building_type?.name}</DataText>
                    <Input
                      name={'building_type_id'}
                      value={data.project?.building_type?.id}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'building_sub_type'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <FormControl
                    sx={{ marginTop: '1rem' }}
                    fullWidth
                    disabled={!buildingTypeId}
                  >
                    <SelectInputLabel id={'building-subtype-label'}>
                      Building Subtype *
                    </SelectInputLabel>
                    <Select
                      name={'building_sub_type_id'}
                      labelId={'building-subtype-label'}
                      error={error}
                      label={'Building Subtype *'}
                      value={buildingSubTypeId}
                      required={true}
                      onChange={(e) =>
                        setBuildingSubTypeId(e.target.value.toString())
                      }
                    >
                      <MenuItem value={''}>None</MenuItem>
                      {buildingSubTypes?.data.map((subtype) => (
                        <MenuItem key={subtype.id} value={subtype.id}>
                          {subtype.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Label>Building subtype</Label>
                  <DataText>{data.project?.building_sub_type?.name}</DataText>
                  <Input
                    name={'building_sub_type_id'}
                    value={data.project?.building_sub_type?.id}
                    sx={{ display: 'none' }}
                  />
                </Box>
              </Fade>
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          {tenureStatusesLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'tenure_status'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <Box>
                    <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                      <SelectInputLabel id={'tenure-status-label'}>
                        Tenure status *
                      </SelectInputLabel>
                      <Select
                        name={'tenure_status_id'}
                        error={error}
                        variant={'outlined'}
                        labelId={'tenure-status-label'}
                        value={tenureStatusId}
                        label={'Tenure status'}
                        required={true}
                        onChange={(e) =>
                          setTenureStatusId(e.target.value.toString())
                        }
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {tenureStatuses.data.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.identifier}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Tenure status</Label>
                    <DataText>
                      {data.project?.tenure_status?.identifier}
                    </DataText>
                    <Input
                      name={'tenure_status_id'}
                      value={data.project?.tenure_status?.id}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          <WizardFormItem
            pageId={pageId}
            attributeName={'building_constructed_in'}
            renderFormField={(error) => (
              <Fade in={true}>
                <Box>
                  <TextField
                    onChange={(e) =>
                      setBuildingConstructedIn(Number(e.target.value))
                    }
                    value={buildingConstructedIn}
                    name={'building_constructed_in'}
                    error={error}
                    type={'number'}
                    label={'Building constructed in'}
                    required={true}
                  />
                </Box>
              </Fade>
            )}
            renderTextField={() => (
              <Fade in={true}>
                <Box>
                  <Label>Building constructed in</Label>
                  <DisabledInput
                    name={'building_constructed_in'}
                    value={data.project?.building_constructed_in}
                  />
                </Box>
              </Fade>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BuildingDetails
