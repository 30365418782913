import Page from '../../components/Page'
import { Typography, Box, Button } from '@mui/material'
import { RenovationStep } from '../../types'
import { IndexResponse } from '../../types/Requests'
import useFetchResource from '../../hooks/useFetchResource'
import Spinner from '../../components/Spinner'
import RenovationStepCard from '../../components/project/renovation_steps/RenovationStepCard'
import { SheetUploadform } from '../../components/project/renovation_steps/SheetUploadform'
import { PartnerCountryContext } from '../../contexts/PartnerCountryContext'
import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../contexts/ProjectContext'
import Label from '../../components/typography/Label'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SubmitProject } from '../../helpers/SubmitProject'

const Sri = () => {
  const { project } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [srisUpdated, setSrisUpdated] = useState(1)
  const projectId = project!.id
  const [renovationSteps, renovationStepsLoading] = useFetchResource<
    IndexResponse<RenovationStep>
  >(
    `/user_projects/${projectId}/renovation_steps`,
    [project, srisUpdated],
    !project
  )
  const { modules } = useContext(PartnerCountryContext)
  const sriModule = modules.find((module) => module.identifier == 'sri')
  const templatePath = sriModule?.templates[0].path

  const handleSriSubmission = async () => {
    const mepModule = modules.find((module) => module.identifier === 'mep')
    const message = mepModule
      ? 'SRI submitted, please continue with the next module.'
      : 'Project flow completed! You can find the link to the resulting document in the project overview.'
    const basePath = `/projects/${projectId}`
    const path = mepModule ? 'mep' : ''
    if (path || (await SubmitProject(projectId))) {
      navigate(`${basePath}/${path}`)
      toast(message, { type: 'success' })
    }
  }

  return (
    <Page>
      <Typography variant={'h3'} marginBottom={4}>
        SRI
      </Typography>
      <Box marginBottom={4}>
        <Typography marginY={4}>
          During this step, you as an auditor can calculate the Smart Readiness
          Indicator (SRI) of the building you are analysing. The template for
          the calculations is provided in the link below. In the first few
          sheets of the document, you can find a more detailed explanation of
          how to get to the resulting SRI values.
        </Typography>
        <Typography marginBottom={4}>
          Please do not change the location of any fields in the spreadsheets.
          Corrupted documents will not be accepted.
        </Typography>
        <Button color={'primary'} variant={'contained'} href={templatePath}>
          Get template
        </Button>
      </Box>
      {renovationStepsLoading ? (
        <Spinner />
      ) : (
        renovationSteps.data.map((step) => (
          <RenovationStepCard
            renovationStepId={step.id}
            key={`step${step.id}}`}
            allowEdit={false}
          >
            <Box marginX={4} paddingTop={4}>
              <Label>SRI Score</Label>
              <Typography marginTop={1} fontWeight={'bold'}>
                {step.sri_score}
              </Typography>
              <SheetUploadform
                sheetsUploaded={srisUpdated}
                title={'Upload SRI sheet'}
                successMessage={'SRI values successfully uploaded!'}
                sheetAttributeName={'sri_sheet'}
                setSheetsUploaded={setSrisUpdated}
                renovationStepId={step.id}
              />
            </Box>
          </RenovationStepCard>
        ))
      )}
      <Box
        marginTop={4}
        display={'flex'}
        minWidth={'100%'}
        justifyContent={'end'}
      >
        <Button variant={'contained'} onClick={handleSriSubmission}>
          Continue
        </Button>
      </Box>
    </Page>
  )
}

export default Sri
