import React, { useContext, useEffect } from 'react'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { userSession, userSessionFetched } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (userSessionFetched && !userSession) {
      navigate('/users/login')
      toast('Please log in to access this page.', { type: 'warning' })
    }
  }, [userSessionFetched])

  return userSession ? <> {children} </> : <></>
}

export default ProtectedRoute
