import { Routes as Paths, Route } from 'react-router-dom'
import Login from './pages/users/Login'
import Index from './pages/Index'
import Ieq from './pages/module_steps/Ieq'
import Sri from './pages/module_steps/Sri'
import Mepi from './pages/module_steps/Mepi'
import ProjectsIndex from './pages/projects/Index'
import NewProjectPage from './pages/projects/New'
import ProtectedRoute from './components/router/ProtectedRoute'
import Project from './pages/projects/Project'
import RenovationStepsIndex from './pages/renovation_steps/Index'
import { ProjectsOutlet } from './components/router/ProjectsOutlet'
import { User } from './pages/users/User'

const Routes = () => {
  return (
    <Paths>
      <Route
        element={
          <ProtectedRoute>
            <ProjectsOutlet />
          </ProtectedRoute>
        }
      >
        <Route path='/projects' element={<ProjectsIndex />} />
        <Route path={'/projects/:projectId'} element={<Project />} />
        <Route path={'/projects/new'} element={<NewProjectPage />} />
        <Route
          path={'/projects/:projectId/renovation_steps'}
          element={<RenovationStepsIndex />}
        />
        <Route path={'/projects/:projectId/ieq'} element={<Ieq />} />
        <Route path={'/projects/:projectId/sri'} element={<Sri />} />
        <Route path={'/projects/:projectId/mep'} element={<Mepi />} />
        <Route
          path={'/projects/:projectId/edit'}
          element={<NewProjectPage />}
        />
      </Route>
      <Route path='/' element={<Index />} />
      <Route path='/users'>
        <Route element={<Login />} path={'login'} />
        <Route element={<User />} path={'/users/:userId'} />
      </Route>
    </Paths>
  )
}

export default Routes
