import Typography from '@mui/joy/Typography'
import { Stack } from '@mui/material'

const Form = ({
  children,
  onSubmit,
  encType,
  title
}: {
  children: React.ReactNode
  onSubmit: React.FormEventHandler
  encType?: string
  title?: string
}) => {
  return (
    <>
      {title ? <Typography level='h4'>{title}</Typography> : <></>}
      <form onSubmit={onSubmit} encType={encType}>
        <Stack>{children}</Stack>
      </form>
    </>
  )
}

export default Form
