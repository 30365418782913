import React from 'react'
import Project from '../../types/Project'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import Label from '../typography/Label'
import DataText from '../typography/DataText'
import Button from '../Button'
import { Download, Visibility } from '@mui/icons-material'

const ProjectInformation = ({ project }: { project: Project }) => {
  const downloadLinkPdf = async () => {
    const response = await fetch(
      `${process.env['REACT_APP_SERVER_ROOT_PATH']}/user_projects/${project.id}/enhanced_epc_pdf`,
      {
        credentials: 'include'
      }
    )
    const blob = await response.blob()
    const fileUrl = window.URL.createObjectURL(blob)
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = fileUrl
    link.download = `enhanced_epc_link_${project.name}.pdf`
    link.click()
    URL.revokeObjectURL(fileUrl)
  }
  const navigateToEnhancedEPC = () => {
    window.location.href = project.enhanced_epc_url
  }
  return (
    <Card variant='outlined'>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='h4' textAlign={'center'}>
              {project.name}
            </Typography>
          </Grid>
          <Grid display={'flex'} justifyContent={'end'} item xs={12} md={4}>
            {project.enhanced_epc_url ? (
              <>
                <Button
                  sx={{ margin: '0' }}
                  variant={'text'}
                  color={'info'}
                  icon={<Visibility />}
                  onClick={navigateToEnhancedEPC}
                >
                  Enhanced EPC
                </Button>
                {project.state == 'submitted' ? (
                  <Button
                    sx={{ margin: '0', marginLeft: 4 }}
                    variant={'contained'}
                    color={'primary'}
                    icon={<Download />}
                    onClick={downloadLinkPdf}
                  >
                    Enhanced EPC
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Label>Client Number</Label>
            <DataText>{project.client_number}</DataText>

            <Label>User</Label>
            <DataText>{project.user.email}</DataText>

            <Label>Country</Label>
            <DataText>{project.partner_country.name}</DataText>

            <Label>Environment</Label>
            <DataText>{project.environment.name}</DataText>

            <Label>Building Type</Label>
            <DataText>{project.building_type.name}</DataText>

            <Label>Building Subtype</Label>
            <DataText>{project.building_sub_type.name}</DataText>

            <Label>Current Energy Class</Label>
            <DataText color={project.current_energy_class.color_hex}>
              {project.current_energy_class.identifier}
            </DataText>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label>Project Trigger</Label>
            <DataText>{project.project_trigger.identifier}</DataText>

            <Label>Project Receiver</Label>
            <DataText>{project.project_receiver.identifier}</DataText>

            <Label>Recommendations target</Label>
            <DataText>{project.recommendations_target.identifier}</DataText>

            <Label>Tenure Status</Label>
            <DataText>{project.tenure_status.identifier}</DataText>

            <Label>Building constructed in</Label>
            <DataText>{project.building_constructed_in}</DataText>

            <Label>Heating system constructed in</Label>
            <DataText>{project.heating_system_constructed_in}</DataText>

            <Label>Cooling system constructed in</Label>
            <DataText>{project.cooling_system_constructed_in}</DataText>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProjectInformation
