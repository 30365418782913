import React, { useContext } from 'react'
import Page from '../../components/Page'
import RenovationSteps from '../../components/project/renovation_steps/RenovationSteps'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Button, Box } from '@mui/material'
import RenovationStep from '../../types/RenovationStep'
import { toast } from 'react-toastify'
import { ProjectContext } from '../../contexts/ProjectContext'
import { PartnerCountryContext } from '../../contexts/PartnerCountryContext'

const Index = () => {
  const { project } = useContext(ProjectContext)
  const { modules } = useContext(PartnerCountryContext)
  const { projectId } = useParams()
  const { state } = useLocation()
  let showProjectWizardButton = false
  if (state) {
    const { projectFlow } = state
    showProjectWizardButton = projectFlow
  }
  const navigate = useNavigate()

  const handleStepSubmission = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/user_projects/${projectId}/renovation_steps`,
      {
        credentials: 'include'
      }
    )
    const data = await res.json()
    const renovationSteps = data['data'] as RenovationStep[]
    const ieqModule = modules.find((module) => module.identifier == 'ieq')
    const sriModule = modules.find((module) => module.identifier == 'sri')
    const mepModule = modules.find((module) => module.identifier == 'mep')
    const ieqApplicable =
      ieqModule &&
      (project!.building_type.identifier === 'residential' ||
        project!.building_sub_type.identifier === 'office_building' ||
        project!.building_sub_type.identifier === 'school')
    const basePath = `/projects/${projectId}`
    const path = ieqApplicable
      ? `ieq`
      : sriModule
        ? 'sri'
        : mepModule
          ? 'mep'
          : ''
    const lastStep = renovationSteps?.pop()
    if (lastStep?.energy_class?.id) {
      navigate(`${basePath}/${path}`)
      toast(
        path
          ? 'Renovation steps submitted! Please continue with the next module'
          : 'Project flow completed! You can find the link to the resulting document in the project overview. ',
        { type: 'success' }
      )
    } else {
      toast('Please add an energy class to the final step before continuing.', {
        type: 'error'
      })
    }
  }

  return (
    <Page>
      <RenovationSteps projectId={Number(projectId)} />
      {showProjectWizardButton ? (
        <Box
          marginTop={4}
          display={'flex'}
          minWidth={'100%'}
          justifyContent={'end'}
        >
          <Button variant={'contained'} onClick={handleStepSubmission}>
            Continue
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Page>
  )
}

export default Index
