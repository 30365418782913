import { Id } from '../types/Generics'
import { toast } from 'react-toastify'

export async function SubmitProject(projectId: Id['id']) {
  const res = await fetch(
    `${process.env.REACT_APP_SERVER_ROOT_PATH}/user_projects/${projectId}`,
    {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({ state: 'submitted' }),
      headers: {
        'Content-Type': 'Application/json'
      }
    }
  )

  if (res.ok) {
    return true
  } else {
    toast('Something went wrong submitting the project. Please try again', {
      type: 'error'
    })
    return false
  }
}
