import { Box, Select, MenuItem, Typography } from '@mui/material'
import Button from '../../Button'
import React, { useEffect } from 'react'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import EnergySource from '../../../types/EnergySource'
import User from '../../../types/User'
import Spinner from '../../Spinner'
import { Id } from '../../../types/Generics'
import { Add, Remove } from '@mui/icons-material'

type EnergySourceFieldsProps = {
  energySourceIds: Id['id'][]
  setEnergySourceIds: (sources: Id['id'][]) => void
  user: User
}

const EnergySourceFields = ({
  energySourceIds,
  setEnergySourceIds,
  user
}: EnergySourceFieldsProps) => {
  const [availableEnergySources, availableEnergySourcesLoading] =
    useFetchResource<IndexResponse<EnergySource>>(
      `/partner_countries/${user?.partner_country_id}/energy_sources`,
      [user],
      !user
    )

  const addEnergySource = () => {
    availableEnergySources &&
      setEnergySourceIds([
        ...energySourceIds,
        availableEnergySources.data[0].id
      ])
  }

  const removeEnergySource = () => {
    setEnergySourceIds(energySourceIds.slice(0, -1))
  }

  useEffect(() => {
    energySourceIds.length === 0 && addEnergySource()
  }, [availableEnergySources])

  return (
    <Box>
      <Typography variant='h6' marginBottom={1}>
        Energy Sources
      </Typography>
      {availableEnergySourcesLoading ? (
        <Spinner height={50} />
      ) : (
        <Box>
          <Box marginBottom={1} display={'flex'} gap={1} flexWrap={'wrap'}>
            {energySourceIds?.map((id, index) => (
              <Select
                key={index}
                value={id}
                onChange={(e) =>
                  setEnergySourceIds(
                    energySourceIds.map((val, i) =>
                      i === index ? Number(e.target.value) : val
                    )
                  )
                }
              >
                {availableEnergySources.data.map((energySource) => (
                  <MenuItem key={energySource.id} value={energySource.id}>
                    {energySource.name}
                  </MenuItem>
                ))}
              </Select>
            ))}
          </Box>
          <Button
            variant={'contained'}
            icon={<Add />}
            sx={{ marginRight: 2 }}
            onClick={addEnergySource}
          >
            Add
          </Button>
          <Button
            variant={'contained'}
            icon={<Remove />}
            onClick={removeEnergySource}
          >
            Remove last
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EnergySourceFields
