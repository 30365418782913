import React from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { capitalize } from '../../helpers/utility'

type SubmitErrorsProps = {
  submitErrors: { [key: string]: string[] } | undefined
}

const SubmitErrors = ({ submitErrors }: SubmitErrorsProps) => {
  return (
    <>
      {submitErrors && (
        <Alert severity='error' sx={{ marginBottom: '2rem' }}>
          <AlertTitle>Something went wrong</AlertTitle>
          <ul>
            {Object.keys(submitErrors).map((key, i) => (
              <li key={i}>
                {capitalize(key.replace(/_id$/, '').replaceAll('_', ' '))}{' '}
                {submitErrors[key]}
              </li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  )
}

export default SubmitErrors
