import React, { useContext, useEffect, useState } from 'react'
import TextField from '../../forms/TextField'
import { Grid, Typography, Fade, Box } from '@mui/material'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'

const EpcUpload = ({ pageId }: { pageId: number }) => {
  const { currentStep, setters, data } = useContext(ProjectWizardContext)
  const [previousEpc, setPreviousEpc] = useState<File | undefined>()

  useEffect(() => {
    setters.setProject({
      ...data.project,
      previous_epc: previousEpc
    })

    if (previousEpc) {
      setters.setAllowNext(true)
    }
  }, [previousEpc])

  return (
    <>
      <Typography variant='h5' marginTop={3} display={'block'}>
        EPC Document
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Fade
            in={currentStep === pageId}
            timeout={1000}
            unmountOnExit
            exit={false}
          >
            <Box>
              <TextField
                onChange={(e) => {
                  setPreviousEpc(e.target.value as unknown as File)
                }}
                type={'file'}
                id={'previous_epc_input'}
                name={'previous_epc'}
                required={true}
              />
            </Box>
          </Fade>
        </Grid>
      </Grid>
    </>
  )
}

export default EpcUpload
