import React from 'react'

import { Fade as MUIFade, FadeProps } from '@mui/material'

const Fade = (props: FadeProps) => {
  return (
    <MUIFade {...props} timeout={1000} unmountOnExit exit={false}>
      {props.children}
    </MUIFade>
  )
}

export default Fade
