import { User } from '../../types'
import Form from '../forms/Form'
import React, { FormEvent, useState } from 'react'
import { Box, Grid } from '@mui/material'
import TextField from '../forms/TextField'
import SubmitErrors from '../forms/SubmitErrors'
import Button from '../Button'
import { SendRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'

export const UserForm = ({
  user,
  rerender,
  setRerender,
  setShowForm
}: {
  user: User
  rerender: number
  setRerender: (e: number) => void
  setShowForm: (e: boolean) => void
}) => {
  const [firstName, setFirstName] = useState<string>(user.first_name)
  const [lastName, setLastName] = useState<string>(user.last_name)
  const [email, setEmail] = useState<string>(user.email)
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phone_number)
  const [submitErrors, setSubmitErrors] = useState()
  const handleSubmission = async (e: FormEvent) => {
    e.preventDefault()
    const body = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email
    }
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/users/${user.id}`,
      {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(body)
      }
    )

    const data = await res.json()

    if (res.ok) {
      toast('User successfully updated!', { type: 'success' })
      setShowForm(false)
      setRerender(rerender + 1)
    } else {
      setSubmitErrors(data.error_description)
    }
  }

  return (
    <Form onSubmit={handleSubmission} title={'Edit user'}>
      <SubmitErrors submitErrors={submitErrors} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type='string'
            id='first_name'
            label={`First name`}
            error={
              submitErrors && Object.keys(submitErrors).includes('first_name')
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type='string'
            id='last_name'
            label={`Last name`}
            error={
              submitErrors && Object.keys(submitErrors).includes('last_name')
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='string'
            id='email'
            label={`Email`}
            error={submitErrors && Object.keys(submitErrors).includes('email')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type='string'
            id='phone_number'
            label={`Phone number`}
            error={
              submitErrors && Object.keys(submitErrors).includes('phone_number')
            }
          />
        </Grid>
      </Grid>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          variant={'contained'}
          color={'success'}
          type='submit'
          icon={<SendRounded />}
        >
          Submit
        </Button>
      </Box>
    </Form>
  )
}
