import Form from '../../forms/Form'
import TextField from '../../forms/TextField'
import { Box, Button } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

export const SheetUploadform = ({
  renovationStepId,
  sheetsUploaded,
  setSheetsUploaded,
  title,
  sheetAttributeName,
  successMessage
}: {
  renovationStepId: number
  sheetsUploaded: number
  title: string
  successMessage: string
  sheetAttributeName: string
  setSheetsUploaded: (n: number) => void
}): JSX.Element => {
  const [sheet, setSheet] = useState<File>()

  const submitSheet = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/renovation_steps/${renovationStepId}`,
      {
        method: 'PATCH',
        credentials: 'include',
        body: formData
      }
    )

    if (res.ok) {
      toast(successMessage, { type: 'success' })
      setSheetsUploaded(sheetsUploaded + 1)
      setSheet(undefined)
    }
  }

  return (
    <Box paddingY={2}>
      <Form onSubmit={submitSheet} title={title}>
        <TextField
          onChange={(e) => {
            setSheet(e.target.value as unknown as File)
          }}
          value={sheet}
          type={'file'}
          id={sheetAttributeName}
          name={sheetAttributeName}
          required={true}
        />
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={(e) => e.stopPropagation()}
          type={'submit'}
        >
          Upload sheet
        </Button>
      </Form>
    </Box>
  )
}
