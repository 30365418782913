import useFetchResource from '../../hooks/useFetchResource'
import Form from '../../components/forms/Form'
import { User } from '../../types'
import React, { FormEvent, useContext, useState } from 'react'
import Page from '../../components/Page'
import AuthContext from '../../contexts/AuthContext'
import { toast } from 'react-toastify'
import ProjectWizardContext from '../../contexts/ProjectWizardContext'
import Navigation from '../../components/project/creation_wizard/Navigation'
import BuildingDetails from '../../components/project/creation_wizard/BuildingDetails'
import EnvironmentDetails from '../../components/project/creation_wizard/EnvironmentDetails'
import EpcUpload from '../../components/project/creation_wizard/EpcUpload'
import { TransitionGroup } from 'react-transition-group'
import { Fade, Box, Typography } from '@mui/material'
import { Project } from '../../types'
import EnergyClassDetails from '../../components/project/creation_wizard/EnergyClassDetails'
import ProjectDetails from '../../components/project/creation_wizard/ProjectDetails'
import { useNavigate } from 'react-router-dom'
import SubmitErrors from '../../components/forms/SubmitErrors'
import { ProjectContext } from '../../contexts/ProjectContext'

const wizardPages = [
  <BuildingDetails key={1} pageId={1} />,
  <EnvironmentDetails key={2} pageId={2} />,
  <EnergyClassDetails key={3} pageId={3} />,
  <ProjectDetails key={4} pageId={4} />,
  <EpcUpload key={5} pageId={5} />
]

const NewProjectPage = () => {
  const { project: editProject } = useContext(ProjectContext)
  const { userSession } = useContext(AuthContext)
  const [user] = useFetchResource<User>(`/users/${userSession?.id}`)
  const [project, setProject] = useState<Partial<Project> | undefined>(
    editProject || undefined
  )

  const navigate = useNavigate()

  const [submitErrors, setSubmitErrors] = useState()

  const [allowNext, setAllowNext] = useState<boolean>(false)

  const [wizardPage, setWizardPage] = useState<number>(1)

  const wizardPageMaximum = wizardPages.length

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)

    let path = `${process.env.REACT_APP_SERVER_ROOT_PATH}/user_projects`
    if (editProject) path = path + `/${editProject.id}`
    const method = editProject ? 'PATCH' : 'POST'
    const res = await fetch(path, {
      method: method,
      credentials: 'include',
      body: formData
    })

    const data = await res.json()

    if (res.ok) {
      navigate(`/projects/${data.id}/renovation_steps`, {
        state: { projectFlow: true }
      })
      toast('Project was successfully created!', { type: 'success' })
    } else {
      setSubmitErrors(data.error_description)
    }
  }

  const projectWizardContextValue = {
    wizardSteps: wizardPageMaximum,
    currentStep: wizardPage,
    nextStep: () => {
      if (allowNext) {
        setWizardPage(wizardPage + 1)
      } else {
        toast('Please complete all required fields before moving on.', {
          type: 'error'
        })
      }
    },
    allowNext: allowNext,
    validationErrors: submitErrors || {},
    data: {
      user: user,
      project: project
    },
    setters: {
      setAllowNext: setAllowNext,
      setCurrentStep: setWizardPage,
      setProject: setProject
    }
  }

  return (
    <Page>
      <ProjectWizardContext.Provider value={projectWizardContextValue}>
        <SubmitErrors submitErrors={submitErrors} />
        <Typography>{submitErrors && Object.keys(submitErrors)[0]}</Typography>
        <Form
          title={'New Project'}
          encType={'multi-part/form-data'}
          onSubmit={submitForm}
        >
          <TransitionGroup>
            {wizardPages.map(
              (page) =>
                wizardPage >= Number(page.key) && (
                  <Fade in key={page.key} timeout={1000} exit={false}>
                    <Box>{page}</Box>
                  </Fade>
                )
            )}
          </TransitionGroup>
          <Navigation />
        </Form>
      </ProjectWizardContext.Provider>
    </Page>
  )
}

export default NewProjectPage
