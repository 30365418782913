import React, { useContext, useEffect, useState } from 'react'
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  Input
} from '@mui/material'
import Fade from './Fade'
import SelectInputLabel from '../../forms/SelectInputLabel'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import { ClimateZone, Environment } from '../../../types'
import Spinner from '../../Spinner'
import DataText from '../../typography/DataText'
import Label from '../../typography/Label'
import { getRecordById } from '../../../helpers/utility'
import WizardFormItem from './WizardFormItem'
const EnvironmentDetails = ({ pageId }: { pageId: number }) => {
  const { currentStep, setters, data } = useContext(ProjectWizardContext)
  const { project } = data

  const [climateZoneId, setClimateZoneId] = useState<string>(
    `${project?.climate_zone?.id}`
  )
  const [environmentId, setEnvironmentId] = useState<string>(
    `${project?.environment?.id}`
  )

  const [climateZones, climateZonesLoading] = useFetchResource<
    IndexResponse<ClimateZone>
  >(
    `/partner_countries/${data.user?.partner_country_id}/climate_zones`,
    [data.user, project],
    !data.user
  )

  const [environments, environmentsLoading] = useFetchResource<
    IndexResponse<Environment>
  >(
    `/partner_countries/${data.user?.partner_country_id}/environments`,
    [data.user, project],
    !data.user
  )

  const setAllowNext = () => {
    setters.setAllowNext(climateZoneId.length >= 1 && environmentId.length >= 1)
  }

  useEffect(() => {
    setClimateZoneId(data.project?.climate_zone?.id.toString() || '')
    setEnvironmentId(data.project?.environment?.id.toString() || '')
  }, [])

  useEffect(() => {
    currentStep === pageId && setAllowNext()
  }, [currentStep])

  useEffect(() => {
    setters.setProject({
      ...data.project,
      climate_zone:
        getRecordById(climateZones?.data, climateZoneId) ||
        data.project?.climate_zone,
      environment:
        getRecordById(environments?.data, environmentId) ||
        data.project?.environment
    })

    setAllowNext()
  }, [climateZoneId, environmentId])

  return (
    <div>
      <>
        <Typography variant='h5' marginTop={3} display={'block'}>
          Environment details
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} lg={4}>
            {climateZonesLoading ? (
              <Spinner height={50} />
            ) : (
              <WizardFormItem
                pageId={pageId}
                attributeName={'climate_zone'}
                renderFormField={(error) => (
                  <Fade in={true}>
                    <Box>
                      <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                        <SelectInputLabel id={'climate-zone-label'}>
                          Climate Zone *
                        </SelectInputLabel>
                        <Select
                          name={'climate_zone_id'}
                          error={error}
                          variant={'outlined'}
                          labelId={'climate-zone-label'}
                          value={climateZoneId}
                          label={'Climate Zone'}
                          required={true}
                          onChange={(e) =>
                            setClimateZoneId(e.target.value.toString())
                          }
                        >
                          <MenuItem value={''}>None</MenuItem>
                          {climateZones.data.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.identifier}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Fade>
                )}
                renderTextField={() => (
                  <Fade in={true}>
                    <Box>
                      <Label>Climate Zone</Label>
                      <DataText>
                        {data.project?.climate_zone?.identifier}
                      </DataText>
                      <Input
                        name={'climate_zone_id'}
                        value={data.project?.climate_zone?.id}
                        sx={{ display: 'none' }}
                      />
                    </Box>
                  </Fade>
                )}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12} lg={4}>
            {environmentsLoading ? (
              <Spinner height={50} />
            ) : (
              <WizardFormItem
                pageId={pageId}
                attributeName={'environment'}
                renderFormField={(error) => (
                  <Fade in={true}>
                    <Box>
                      <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                        <SelectInputLabel id={'environment-label'}>
                          Environment *
                        </SelectInputLabel>
                        <Select
                          name={'environment_id'}
                          error={error}
                          variant={'outlined'}
                          labelId={'environment-label'}
                          value={environmentId}
                          label={'Environment'}
                          required={true}
                          onChange={(e) =>
                            setEnvironmentId(e.target.value.toString())
                          }
                        >
                          <MenuItem value={''}>None</MenuItem>
                          {environments.data.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Fade>
                )}
                renderTextField={() => (
                  <Fade in={true}>
                    <Box>
                      <Label>Environment</Label>
                      <DataText>{data.project?.environment?.name}</DataText>
                      <Input
                        name={'environment_id'}
                        value={data.project?.environment?.id}
                        sx={{ display: 'none' }}
                      />
                    </Box>
                  </Fade>
                )}
              />
            )}
          </Grid>
        </Grid>
      </>
    </div>
  )
}

export default EnvironmentDetails
