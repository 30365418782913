import React from 'react'
import { Typography } from '@mui/material'

type DataTextType = {
  children: React.ReactNode
  color?: string
}

const DataText = ({ children, color }: DataTextType) => {
  return (
    <Typography sx={{ mb: 2 }} color={color ? color : `text.secondary`}>
      {children}
    </Typography>
  )
}

export default DataText
