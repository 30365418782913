import React, { useState } from 'react'
import { Project } from '../../../types'
import Button from '../../Button'
import FetchResource from '../../FetchResource'
import RenovationStep from '../../../types/RenovationStep'
import RenovationStepCard from './RenovationStepCard'
import { Box, Typography } from '@mui/material'
import { Add, CancelOutlined } from '@mui/icons-material'
import NewRenovationStep from './RenovationStepForm'

type RenovationStepsResponse = {
  data: RenovationStep[]
}

const RenovationSteps = ({ projectId }: { projectId: Project['id'] }) => {
  const [showForm, setShowForm] = useState<boolean>(false)

  const [createdRenovationSteps, setCreatedRenovationSteps] =
    useState<number>(0)

  return (
    <Box marginTop={3}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant='h4'>Renovation Steps</Typography>
        <Button
          color={showForm ? 'warning' : 'primary'}
          icon={showForm ? <CancelOutlined /> : <Add />}
          variant='contained'
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? 'Cancel' : 'Add'}
        </Button>
      </Box>
      {showForm && (
        <NewRenovationStep
          projectId={projectId}
          setShowForm={setShowForm}
          setCreatedRenovationSteps={setCreatedRenovationSteps}
          createdRenovationSteps={createdRenovationSteps}
        />
      )}
      <FetchResource<RenovationStepsResponse>
        render={(renovationSteps: RenovationStepsResponse) => {
          return (
            <Box marginTop={2}>
              {renovationSteps.data.map((renovationStep) => (
                <RenovationStepCard
                  rerenders={createdRenovationSteps}
                  setRerenders={setCreatedRenovationSteps}
                  allowEdit={true}
                  renovationStepId={renovationStep.id}
                  key={renovationStep.id}
                />
              ))}
            </Box>
          )
        }}
        path={`/user_projects/${projectId}/renovation_steps`}
        dependencies={[createdRenovationSteps]}
      />
    </Box>
  )
}

export default RenovationSteps
