import Page from '../../components/Page'
import Project from '../../types/Project'
import ProjectCard from '../../components/ProjectCard'
import {
  Box,
  MenuItem,
  Pagination,
  Stack,
  Select,
  Typography
} from '@mui/material'
import Button from '../../components/Button'
import Grid from '@mui/material/Unstable_Grid2'
import FetchResource from '../../components/FetchResource'
import AddIcon from '@mui/icons-material/Add'
import React, { useState, ChangeEvent } from 'react'

import { useNavigate } from 'react-router-dom'
import { IndexResponse } from '../../types/Requests'

type Projects = IndexResponse<Project>

const Index = () => {
  const navigate = useNavigate()
  const [pageAmount, setPageAmount] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  return (
    <Page>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        alignItems={'center'}
      >
        <h1>Projects</h1>
        <Box display={'flex'} alignItems={'center'} height={'1rem'}>
          <Typography marginRight={'1rem'}>Projects per page</Typography>
          <Select
            variant={'outlined'}
            value={pageAmount}
            sx={{ height: '2rem' }}
            onChange={(e) => setPageAmount(Number(e.target.value))}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </Box>
        <Button
          variant={'contained'}
          onClick={() => navigate('/projects/new')}
          icon={<AddIcon />}
        >
          Add project
        </Button>
      </Box>
      <FetchResource<Projects>
        path={`/user_projects?perPage=${pageAmount}&page=${page}`}
        dependencies={[page, pageAmount]}
        render={(projects, headers) => (
          <>
            <Grid container spacing={2}>
              {projects.data.map((project) => (
                <Grid key={project.id} xs={12} md={6}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>
            <Stack alignItems={'center'} marginTop={3}>
              <Pagination
                count={(headers && Number(headers.get('total-pages'))) || 1}
                page={page}
                onChange={(_event: ChangeEvent<unknown>, page: number) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                  setPage(page)
                }}
              />
            </Stack>
          </>
        )}
      />
    </Page>
  )
}

export default Index
