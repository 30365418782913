import React, { ReactElement } from 'react'
import useFetchResource from '../hooks/useFetchResource'
import Spinner from './Spinner'
import FetchError from './FetchError'
import { IndexResponseHeaderType } from '../types/Requests'

type FetchResourceProps<T> = {
  render: (data: T, headers?: IndexResponseHeaderType) => ReactElement
  path: string
  dependencies?: React.DependencyList
}

function FetchResource<T>({
  render,
  path,
  dependencies
}: FetchResourceProps<T>) {
  const [data, loading, error, headers] = useFetchResource<T>(
    path,
    dependencies
  )

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <FetchError error={error} />
  }

  return render(data, headers)
}

export default FetchResource
