import Page from '../../components/Page'
import { Typography, Box, Button, Grid } from '@mui/material'
import { RenovationStep } from '../../types'
import { IndexResponse } from '../../types/Requests'
import useFetchResource from '../../hooks/useFetchResource'
import Spinner from '../../components/Spinner'
import RenovationStepCard from '../../components/project/renovation_steps/RenovationStepCard'
import { SheetUploadform } from '../../components/project/renovation_steps/SheetUploadform'
import { PartnerCountryContext } from '../../contexts/PartnerCountryContext'
import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../contexts/ProjectContext'
import Label from '../../components/typography/Label'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SubmitProject } from '../../helpers/SubmitProject'
import DataText from '../../components/typography/DataText'

const Mepi = () => {
  const { project } = useContext(ProjectContext)
  const navigate = useNavigate()
  const [mepsUpdated, setMepsUpdated] = useState(1)
  const projectId = project!.id
  const [renovationSteps, renovationStepsLoading] = useFetchResource<
    IndexResponse<RenovationStep>
  >(
    `/user_projects/${projectId}/renovation_steps`,
    [project, mepsUpdated],
    !project
  )
  const { modules } = useContext(PartnerCountryContext)
  const mepModule = modules.find((module) => module.identifier == 'mep')
  const templatePath = mepModule?.templates[0].path

  const handleMepSubmission = async () => {
    const message =
      'Project flow completed! You can find the link to the resulting document in the project overview.'
    const path = `/projects/${projectId}`
    await SubmitProject(projectId)
    navigate(path)
    toast(message, { type: 'success' })
  }

  return (
    <Page>
      <Typography variant={'h3'} marginBottom={4}>
        MEPI
      </Typography>
      <Box marginBottom={4}>
        <Typography marginY={4}>
          During this step, you as an auditor can calculate the Measured Energy
          Performance Indicator (MEPI) of the building you are analysing. The
          template for the calculations is provided in the link below.
        </Typography>
        <Typography marginBottom={4}>
          Please do not change the location of any fields in the spreadsheets.
          Corrupted documents will not be accepted.
        </Typography>
        <Button color={'primary'} variant={'contained'} href={templatePath}>
          Get template
        </Button>
      </Box>
      {renovationStepsLoading ? (
        <Spinner />
      ) : (
        renovationSteps.data.map((step) => (
          <RenovationStepCard
            renovationStepId={step.id}
            key={`step${step.id}}`}
            allowEdit={false}
          >
            <Box marginX={4} paddingTop={4}>
              <Label>MEPI Score</Label>
              <Typography marginTop={1} fontWeight={'bold'}>
                {step.mep_scores && (
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Label>Space heating</Label>
                      <DataText>{step.mep_scores['space_heating']}</DataText>
                      <Label>Space cooling</Label>
                      <DataText>{step.mep_scores['space_cooling']}</DataText>
                      <Label>Domestic Hot Water</Label>
                      <DataText>
                        {step.mep_scores['domestic_hot_water']}
                      </DataText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Label>Exported electrical energy</Label>
                      <DataText>
                        {step.mep_scores['exported_electrical_energy']}
                      </DataText>
                      <Label>Electrical energy production</Label>
                      <DataText>
                        {step.mep_scores['electrical_energy_production']}
                      </DataText>
                      <Label>Other</Label>
                      <DataText>{step.mep_scores['other']}</DataText>
                    </Grid>
                  </Grid>
                )}
              </Typography>
              <SheetUploadform
                sheetsUploaded={mepsUpdated}
                title={'Upload MEPI sheet'}
                successMessage={'MEPI values successfully uploaded!'}
                sheetAttributeName={'mep_sheet'}
                setSheetsUploaded={setMepsUpdated}
                renovationStepId={step.id}
              />
            </Box>
          </RenovationStepCard>
        ))
      )}
      <Box
        marginTop={4}
        display={'flex'}
        minWidth={'100%'}
        justifyContent={'end'}
      >
        <Button variant={'contained'} onClick={handleMepSubmission}>
          Continue
        </Button>
      </Box>
    </Page>
  )
}

export default Mepi
