import { useEffect, useState, DependencyList } from 'react'
import { toast } from 'react-toastify'
import { FetchError, IndexResponseHeaderType } from '../types/Requests'
import { useNavigate } from 'react-router-dom'

function useFetchResource<T>(
  path: string,
  deps: DependencyList = [],
  wait?: boolean
): [T, boolean, FetchError | undefined, IndexResponseHeaderType | undefined] {
  const [data, setData] = useState<T | undefined>()
  const [headers, setHeaders] = useState<IndexResponseHeaderType | undefined>(
    undefined
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<FetchError | undefined>(undefined)
  const navigate = useNavigate()

  const executeFetch = async () => {
    const getData = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_ROOT_PATH}${path}`,
        {
          credentials: 'include'
        }
      )

      try {
        const data = await res.json()
        if (res.status == 401) {
          navigate('/users/login')
        }

        if (res.status == 200) {
          setData(data)
          setHeaders(res.headers)
        } else setError(data)
      } catch (error) {
        toast('Something went wrong. Please try again later.', {
          type: 'error'
        })
      }

      setLoading(false)
    }

    getData()
  }

  useEffect(() => {
    if (!wait) {
      executeFetch()
    }
  }, deps)

  return [data as T, loading, error, headers]
}

export default useFetchResource
