import { Button, FormControl, Container } from '@mui/material'
import TextField from '../../components/forms/TextField'
import Form from '../../components/forms/Form'
import { FormEvent, useState, useContext } from 'react'
import { loginUser } from '../../types/User'
import { useNavigate } from 'react-router-dom'
import Page from '../../components/Page'
import UserContext from '../../contexts/AuthContext'
import {
  AuthResponse,
  FailedAuthentication,
  SuccessfulAuthentication
} from '../../types/Requests'
import { toast } from 'react-toastify'

const Login = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const navigate = useNavigate()

  const { setUserSession } = useContext(UserContext)

  const loginUser = async (user: loginUser): Promise<AuthResponse> => {
    const root = process.env[`REACT_APP_SERVER_ROOT_PATH`]
    const url = `${root}/users/login`
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'Application/json' },
      body: JSON.stringify(user),
      credentials: 'include'
    })
    return res
  }

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (email.length > 1 && password.length > 1) {
      const response = await loginUser({ email: email, password: password })
      const body = await response.json()

      if (response.status == 200) {
        const data = body as SuccessfulAuthentication

        setUserSession({ id: data.id, email: data.email })
        navigate('/')
        toast('Successfully logged in!', { type: 'success' })
      } else {
        const data = body as FailedAuthentication

        data.error_description.forEach((error) => {
          toast(error, { type: 'error' })
        })
      }
    }
  }

  return (
    <Page>
      <Container maxWidth='sm'>
        <Form onSubmit={submitHandler} title={'Log in'}>
          <FormControl>
            <TextField
              type={'email'}
              value={email}
              label={'Email'}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <TextField
              label={'Password'}
              type={'password'}
              value={password}
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button variant={'contained'} type={'submit'}>
            Login
          </Button>
        </Form>
      </Container>
    </Page>
  )
}

export default Login
