import React, { ReactNode, useContext, useState } from 'react'
import RenovationStep from '../../../types/RenovationStep'
import Button from './../../../components/Button'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Box,
  Stack,
  Chip
} from '@mui/material'
import {
  Add,
  CancelOutlined,
  Edit,
  ExpandMore,
  Delete
} from '@mui/icons-material'
import { RenovationMeasureForm } from './renovation_measures/RenovationMeasureForm'
import { RenovationMeasureCard } from './renovation_measures/RenovationMeasureCard'
import useFetchResource from '../../../hooks/useFetchResource'
import { RenovationMeasure } from '../../../types'
import { IndexResponse } from '../../../types/Requests'
import Spinner from '../../Spinner'
import RenovationStepForm from './RenovationStepForm'
import { toast } from 'react-toastify'
import { RegulationTargetCard } from './regulation_targets/RegulationTargetCard'
import { ProjectContext } from '../../../contexts/ProjectContext'

const RenovationStepCard = ({
  renovationStepId,
  allowEdit,
  children,
  rerenders,
  setRerenders
}: {
  renovationStepId: RenovationStep['id']
  allowEdit: boolean
  rerenders?: number
  setRerenders?: (a: number) => void
  children?: ReactNode
}) => {
  const [showMeasureForm, setShowMeasureForm] = useState<boolean>(false)
  const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false)
  const [createdMeasures, setCreatedMeasures] = useState<number>(0)
  const { project } = useContext(ProjectContext)

  const [renovationStep, renovationStepLoading] =
    useFetchResource<RenovationStep>(`/renovation_steps/${renovationStepId}`, [
      createdMeasures,
      rerenders
    ])
  const [renovationMeasures, renovationMeasuresLoading] = useFetchResource<
    IndexResponse<RenovationMeasure>
  >(`/renovation_steps/${renovationStepId}/renovation_measures`, [
    createdMeasures,
    rerenders
  ])

  const toggleUpdateForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setShowMeasureForm(false)
    setShowUpdateForm(!showUpdateForm)
  }

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (confirm('Are you sure you want to delete this renovation step?')) {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_ROOT_PATH}/renovation_steps/${renovationStepId}`,
        { method: 'DELETE', credentials: 'include' }
      )

      if (res.ok) {
        toast('Renovation step was succesfully deleted.', { type: 'success' })
        setRerenders!(rerenders! + 1)
      } else {
        toast(
          'There was a problem deleting this renovation step. Try again later.',
          {
            type: 'error'
          }
        )
      }
    } else {
      confirm('Unable to delete this step')
    }
  }

  let sortedMeasures: RenovationMeasure[] = []
  if (renovationMeasures) {
    sortedMeasures = renovationMeasures?.data?.sort(
      (a, b) => a.position - b.position
    )
  }

  return renovationStepLoading ? (
    <Spinner />
  ) : (
    <>
      <Accordion defaultExpanded={true}>
        {showUpdateForm ? (
          <Box padding={3} width={'100%'}>
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Typography variant='h5' color='primary'>
                Edit renovation step
              </Typography>
              <Button
                onClick={toggleUpdateForm}
                color={'warning'}
                icon={<CancelOutlined />}
                variant='contained'
                type={'button'}
              >
                Cancel
              </Button>
            </Box>
            <RenovationStepForm
              projectId={renovationStep.project_id}
              renovationStep={renovationStep}
              setShowForm={setShowUpdateForm}
              createdRenovationSteps={createdMeasures}
              setCreatedRenovationSteps={setCreatedMeasures}
            />
          </Box>
        ) : (
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box width={'100%'}>
              <Box display={'flex'} justifyContent={'between'}>
                <Grid container width={'100%'}>
                  {renovationStep.base_step ? (
                    <>
                      <Grid item md={3}>
                        <Typography variant='h5' color='primary'>
                          Current state
                        </Typography>
                      </Grid>
                      {project!.current_energy_class.identifier && (
                        <Grid item md={3}>
                          <Typography color='text.secondary'>
                            Energy class
                          </Typography>
                          <Typography
                            variant='h4'
                            color={project!.current_energy_class.color_hex}
                          >
                            {project!.current_energy_class.identifier}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item md={3}>
                        <Typography color='text.secondary'>
                          Renovations to be done by
                        </Typography>
                        <Typography variant='h4' color='secondary'>
                          {renovationStep.asap
                            ? 'ASAP'
                            : renovationStep.target_year}
                        </Typography>
                      </Grid>
                      {renovationStep.energy_class.identifier && (
                        <Grid item md={3}>
                          <Typography color='text.secondary'>
                            Energy class
                          </Typography>
                          <Typography
                            variant='h4'
                            color={renovationStep.energy_class.color_hex}
                          >
                            {renovationStep.energy_class.identifier}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                {allowEdit ? (
                  <Box display={'flex'} justifyContent={'space-evenly'}>
                    <Button
                      onClick={toggleUpdateForm}
                      color={showUpdateForm ? 'warning' : 'success'}
                      variant='text'
                      type={'button'}
                    >
                      {showMeasureForm ? <CancelOutlined /> : <Edit />}
                    </Button>
                    {renovationStep.base_step ? (
                      <></>
                    ) : (
                      <Button
                        onClick={handleDelete}
                        color={'error'}
                        variant='text'
                        type={'button'}
                      >
                        <Delete />
                      </Button>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              {children ? (
                children
              ) : (
                <>
                  <Grid marginTop={2} container width={'100%'} rowSpacing={1}>
                    {renovationStep.base_step ? (
                      <></>
                    ) : (
                      <>
                        <Grid item md={4}>
                          <Typography color='text.secondary'>
                            Maintenance cost
                          </Typography>
                          <Typography>
                            {renovationStep.maintenance_costs}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography color='text.secondary'>
                            Energy cost
                          </Typography>
                          <Typography>{renovationStep.energy_costs}</Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography color='text.secondary'>
                            Funding
                          </Typography>
                          <Typography>{renovationStep.funding}</Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item md={4}>
                      <Typography color='text.secondary'>
                        Final energy demand
                      </Typography>
                      <Typography>
                        {renovationStep.final_energy_demand}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography color='text.secondary'>
                        GHG Emissions
                      </Typography>
                      <Typography>
                        {renovationStep.greenhouse_emissions}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography color='text.secondary'>
                        Energy costs
                      </Typography>
                      <Typography>
                        {renovationStep.energy_energy_costs}
                      </Typography>
                    </Grid>
                  </Grid>
                  {renovationStep.energy_sources.length > 0 && (
                    <>
                      <Typography color='text.secondary' marginTop={2}>
                        Energy sources
                      </Typography>
                      <Stack direction='row' spacing={2} marginTop={1}>
                        {renovationStep.energy_sources.map((energy_source) => (
                          <Chip
                            key={energy_source.id}
                            label={energy_source.name}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </>
              )}
            </Box>
          </AccordionSummary>
        )}
        <AccordionDetails>
          {renovationMeasuresLoading ? (
            <Spinner />
          ) : (
            sortedMeasures.map((measure) => (
              <RenovationMeasureCard
                key={measure.id}
                renovationMeasure={measure}
                allowPositionUp={measure !== sortedMeasures?.at(0)}
                allowPositionDown={measure !== sortedMeasures?.at(-1)}
                setCreatedMeasures={setRerenders}
                createdMeasures={rerenders}
              />
            ))
          )}
          {renovationStep.base_step ? (
            <></>
          ) : (
            <Box marginTop={2} display={'flex'} justifyContent={'end'}>
              {allowEdit ? (
                <Button
                  onClick={() => setShowMeasureForm(!showMeasureForm)}
                  color={showMeasureForm ? 'warning' : 'primary'}
                  icon={showMeasureForm ? <CancelOutlined /> : <Add />}
                  variant='contained'
                  type={'button'}
                >
                  Renovation measure
                </Button>
              ) : (
                <></>
              )}
            </Box>
          )}
          {showMeasureForm ? (
            <RenovationMeasureForm
              renovationStepId={renovationStep.id}
              projectId={renovationStep.project_id}
              createdMeasures={rerenders}
              setShowForm={setShowMeasureForm}
              setCreatedMeasures={setRerenders}
            />
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>
      {renovationStep.regulation_targets &&
        renovationStep.regulation_targets.map((regulation_target) => (
          <RegulationTargetCard
            key={regulation_target.id}
            regulationTarget={regulation_target}
          />
        ))}
    </>
  )
}

export default RenovationStepCard
