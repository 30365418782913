import React from 'react'
import {
  InputLabel as MUILabel,
  InputLabelProps as MUILabelProps
} from '@mui/material'

interface SelectInputLabelProps extends MUILabelProps {
  backgroundColor?: string
  paddingRight?: string
}

const SelectInputLabel = (props: SelectInputLabelProps) => {
  return (
    <MUILabel
      {...props}
      sx={{
        backgroundColor: props.backgroundColor || 'white',
        paddingRight: props.paddingRight || '4px'
      }}
    >
      {props.children}
    </MUILabel>
  )
}

export default SelectInputLabel
