import {
  MeasureConflict,
  MeasureType,
  RenovationMeasure
} from '../../../../../types'
import { Box, Typography } from '@mui/material'
import { SendRounded } from '@mui/icons-material'
import DataText from '../../../../typography/DataText'
import React, { FormEvent, useState, useContext } from 'react'
import Form from '../../../../forms/Form'
import Button from '../../../../Button'
import TextField from '../../../../forms/TextField'
import SubmitErrors from '../../../../forms/SubmitErrors'
import { toast } from 'react-toastify'
import { Edit, CancelOutlined } from '@mui/icons-material'
import useFetchResource from '../../../../../hooks/useFetchResource'
import { PartnerCountryContext } from '../../../../../contexts/PartnerCountryContext'
import Spinner from '../../../../Spinner'

export const MeasureConflictCard = ({
  measureConflict,
  createdMeasures,
  setCreatedMeasures
}: {
  partnerCountryId: number
  measureConflict: MeasureConflict
  createdMeasures: number | undefined
  setCreatedMeasures: ((e: number) => void) | undefined
}) => {
  const { partnerCountryId } = useContext(PartnerCountryContext)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [submitErrors, setSubmitErrors] = useState()
  const [notes, setNotes] = useState<string>(measureConflict?.notes || '')
  const [triggerMeasure, triggerMeasureLoading] =
    useFetchResource<RenovationMeasure>(
      `/renovation_measures/${measureConflict.trigger_measure_id}`
    )
  const [triggerMeasureType, triggerMeasureTypeLoading] =
    useFetchResource<MeasureType>(
      `/partner_countries/${partnerCountryId}/measure_types/${triggerMeasure?.measure_type_id}`,
      [triggerMeasure],
      triggerMeasureLoading
    )

  const handleMeasureConflictUpdate = async (e: FormEvent) => {
    e.preventDefault()
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/renovation_measures/${measureConflict.trigger_measure_id}/measure_conflicts/${measureConflict.id}`,
      {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify({ notes: notes })
      }
    )

    const data = await res.json()
    if (res.ok) {
      toast('Measure conflict was successfully updated!', { type: 'success' })
      setCreatedMeasures!(createdMeasures! + 1)
      setShowForm(false)
      setSubmitErrors(undefined)
    } else {
      setSubmitErrors(data.error_description)
    }
  }

  return triggerMeasureLoading || triggerMeasureTypeLoading ? (
    <Spinner height={25} />
  ) : (
    <Box display={'flex'} justifyContent={'space-between'}>
      {showForm ? (
        <Box width={'100%'}>
          <SubmitErrors submitErrors={submitErrors} />
          <Form onSubmit={handleMeasureConflictUpdate} title={''}>
            <TextField
              multiline={true}
              label={'Notes'}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant={'contained'}
                color={'success'}
                type='submit'
                icon={<SendRounded />}
              >
                Submit
              </Button>
            </Box>
          </Form>
        </Box>
      ) : (
        <Box>
          <Typography color={'red'} fontWeight={'bold'} fontSize={'medium'}>
            Preparation notes for {triggerMeasureType.name}
          </Typography>
          <DataText>{measureConflict.notes}</DataText>
        </Box>
      )}
      <Box display={'flex'} justifyContent={'end'}>
        <Button
          variant={'text'}
          onClick={() => setShowForm(!showForm)}
          color={showForm ? 'warning' : 'success'}
          type='button'
        >
          {showForm ? <CancelOutlined /> : <Edit />}
        </Button>
      </Box>
    </Box>
  )
}
