import { RenovationMeasure, MeasureType } from '../../../../types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import {
  Warning,
  ExpandMore,
  CancelOutlined,
  Edit,
  Delete,
  ArrowUpward,
  ArrowDownward
} from '@mui/icons-material'
import { RenovationMeasureForm } from './RenovationMeasureForm'
import React, { useContext, useState } from 'react'
import { PartnerCountryContext } from '../../../../contexts/PartnerCountryContext'
import useFetchResource from '../../../../hooks/useFetchResource'
import Spinner from '../../../Spinner'
import Label from '../../../../components/typography/Label'
import DataText from '../../../../components/typography/DataText'
import Button from '../../../Button'
import { ProjectContext } from '../../../../contexts/ProjectContext'
import { toast } from 'react-toastify'
import { MeasureConflictCard } from './measure_conflicts/MeasureConflictCard'

export const RenovationMeasureCard = ({
  renovationMeasure,
  createdMeasures,
  allowPositionUp,
  allowPositionDown,
  setCreatedMeasures
}: {
  renovationMeasure: RenovationMeasure
  setCreatedMeasures: ((number: number) => void) | undefined
  allowPositionUp: boolean
  allowPositionDown: boolean
  createdMeasures: number | undefined
}) => {
  const { partnerCountryId } = useContext(PartnerCountryContext)
  const { project } = useContext(ProjectContext)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [measureType, measureTypeLoading] = useFetchResource<MeasureType>(
    `/partner_countries/${partnerCountryId}/measure_types/${renovationMeasure.measure_type_id}`,
    [renovationMeasure]
  )

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (confirm('Are you sure you want to delete this renovation measure?')) {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_ROOT_PATH}/renovation_steps/${renovationMeasure.renovation_step_id}/renovation_measures/${renovationMeasure.id}`,
        { method: 'DELETE', credentials: 'include' }
      )

      if (res.ok) {
        toast('Renovation measure was succesfully deleted.', {
          type: 'success'
        })
        setCreatedMeasures!(createdMeasures! + 1)
      } else {
        toast(
          'There was a problem deleting this renovation measure. Try again later.',
          {
            type: 'error'
          }
        )
      }
    } else {
      confirm('Unable to delete this measure')
    }
  }

  const handlePositionChange = async (direction: 'up' | 'down') => {
    if (
      confirm('Are you sure you want to change the position of this measure?')
    ) {
      const newPosition =
        direction == 'up'
          ? renovationMeasure.position - 1
          : renovationMeasure.position + 1

      const res = await fetch(
        `${process.env.REACT_APP_SERVER_ROOT_PATH}/renovation_steps/${renovationMeasure.renovation_step_id}/renovation_measures/${renovationMeasure.id}`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'Application/json'
          },
          body: JSON.stringify({ position: newPosition })
        }
      )
      if (res.ok) {
        toast('Position successfully updated!', { type: 'success' })
        setCreatedMeasures!(createdMeasures! + 1)
        setShowForm(false)
      }
    }
  }

  return measureTypeLoading ? (
    <Spinner height={100} />
  ) : measureType?.id ? (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
          maxHeight={'20%'}
        >
          <Box display={'flex'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Button
                sx={{
                  margin: 0,
                  width: '20px',
                  maxHeight: '50%',
                  marginRight: 0.4,
                  visibility: allowPositionUp ? 'visible' : 'hidden'
                }}
                color={'info'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  handlePositionChange('up')
                }}
              >
                <ArrowUpward />
              </Button>
              <Button
                sx={{
                  margin: 0,
                  width: '20px',
                  maxHeight: '50%',
                  marginRight: 0.4,
                  visibility: allowPositionDown ? 'visible' : 'hidden'
                }}
                color={'info'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation()
                  handlePositionChange('down')
                }}
              >
                <ArrowDownward />
              </Button>
            </Box>

            <Typography
              marginY={'auto'}
              paddingBottom={0.5}
              fontWeight={'bold'}
              fontSize={'medium'}
            >
              #{renovationMeasure.position} {measureType.name}
              {renovationMeasure.future_conflicts?.length ? (
                <Warning
                  style={{
                    color: 'red',
                    marginLeft: 2,
                    top: 4,
                    position: 'relative'
                  }}
                />
              ) : (
                <></>
              )}
            </Typography>
          </Box>
          <Box marginY={'auto'}>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                if (!showForm) setExpanded(true)
                setShowForm(!showForm)
              }}
              color={showForm ? 'warning' : 'success'}
              variant='text'
              type={'button'}
              sx={{ margin: 0 }}
            >
              {showForm ? <CancelOutlined /> : <Edit />}
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(e)
              }}
              color={'error'}
              variant='text'
              type={'button'}
              sx={{ margin: 0 }}
            >
              <Delete />
            </Button>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {showForm ? (
          <RenovationMeasureForm
            renovationStepId={renovationMeasure.renovation_step_id}
            renovationMeasure={renovationMeasure}
            setShowForm={setShowForm}
            setCreatedMeasures={setCreatedMeasures}
            createdMeasures={createdMeasures}
            projectId={project!.id}
          />
        ) : (
          <>
            <Label>Description</Label>
            <DataText>{renovationMeasure.description}</DataText>
            <Label>Specification</Label>
            <DataText>{renovationMeasure.specification}</DataText>
            {partnerCountryId &&
              renovationMeasure.future_conflicts?.map(
                (measureConflict, index) => (
                  <MeasureConflictCard
                    partnerCountryId={partnerCountryId}
                    key={measureConflict.id + index}
                    measureConflict={measureConflict}
                    createdMeasures={createdMeasures}
                    setCreatedMeasures={setCreatedMeasures}
                  />
                )
              )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  ) : (
    <Typography>{renovationMeasure.measure_type_id}</Typography>
  )
}
