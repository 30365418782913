import Button from '../Button'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Id } from '../../types/Generics'

const DeleteProjectButton = ({ projectId }: { projectId: Id['id'] }) => {
  const navigate = useNavigate()

  const deleteProject = async () => {
    if (confirm('Are you sure you want to delete this project?')) {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_ROOT_PATH}/user_projects/${projectId}`,
        { method: 'DELETE', credentials: 'include' }
      )

      if (res.ok) {
        navigate('/projects')
        toast('Project was succesfully deleted.', { type: 'success' })
      } else {
        toast('There was a problem deleting this project. Try again later.', {
          type: 'error'
        })
      }
    }
  }

  return (
    <Button
      onClick={deleteProject}
      color='warning'
      variant='text'
      icon={<DeleteIcon />}
    >
      Delete project
    </Button>
  )
}

export default DeleteProjectButton
