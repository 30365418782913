import { Card, CardContent, Typography } from '@mui/material'
import { RegulationTarget } from '../../../../types'

export const RegulationTargetCard = ({
  regulationTarget
}: {
  regulationTarget: RegulationTarget
}) => {
  return (
    <Card sx={{ marginBottom: 4 }}>
      <CardContent>
        <Typography fontWeight={'bold'}>
          Regulation to be considered for{' '}
          {regulationTarget.target_year || 'ASAP'}
        </Typography>
        <Typography color={'darkgreen'}>
          {regulationTarget.regulation}
        </Typography>
      </CardContent>
    </Card>
  )
}
