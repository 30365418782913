import React, { useContext } from 'react'
import { Box } from '@mui/material'
import Button from '../../Button'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { SendRounded } from '@mui/icons-material'

const Navigation = () => {
  const { currentStep, wizardSteps, setters, nextStep } =
    useContext(ProjectWizardContext)

  return (
    <Box display='flex' justifyContent='space-between'>
      {currentStep != 1 ? (
        <Button
          onClick={() => setters.setCurrentStep(currentStep - 1)}
          icon={<ArrowBackIcon />}
          variant={'contained'}
        >
          Back
        </Button>
      ) : (
        <div></div>
      )}

      {currentStep != wizardSteps && (
        <Button
          onClick={() => nextStep()}
          icon={<ArrowForwardIcon />}
          variant={'contained'}
        >
          Next
        </Button>
      )}

      {currentStep === wizardSteps && (
        <Button type='submit' icon={<SendRounded />} variant={'contained'}>
          Submit
        </Button>
      )}
    </Box>
  )
}

export default Navigation
