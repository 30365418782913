import React from 'react'
import { Typography } from '@mui/material'

type LabelType = {
  children: React.ReactNode
}

const Label = ({ children }: LabelType) => {
  return (
    <Typography component={'label'} fontWeight={'bold'}>
      {children}
    </Typography>
  )
}

export default Label
