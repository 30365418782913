import Page from '../../components/Page'
import ProjectType from '../../types/Project'
import { useParams } from 'react-router'
import FetchResource from '../../components/FetchResource'
import ProjectInformation from '../../components/project/ProjectInformation'
import DeleteProjectButton from '../../components/project/DeleteProjectButton'
import { Box } from '@mui/material'
import { Edit } from '@mui/icons-material'
import RenovationSteps from '../../components/project/renovation_steps/RenovationSteps'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

const Project = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()

  return (
    <Page>
      <FetchResource<ProjectType>
        path={`/user_projects/${projectId}`}
        render={(project) => <ProjectInformation project={project} />}
      />
      <Box marginTop={2} display={'flex'} justifyContent={'flex-end'}>
        <DeleteProjectButton projectId={Number(projectId)} />
        <Button
          icon={<Edit />}
          onClick={() => navigate(`/projects/${projectId}/edit`)}
        >
          Edit
        </Button>
      </Box>
      <RenovationSteps projectId={Number(projectId)} />
    </Page>
  )
}

export default Project
