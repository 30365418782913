import { TextField as MaterialTextField } from '@mui/material'
import { Optional } from 'utility-types'
import { ComponentProps } from 'react'

type MaterialUITextFieldProps = ComponentProps<typeof MaterialTextField>

type TextFieldProps = Optional<
  Pick<
    MaterialUITextFieldProps,
    | 'onChange'
    | 'value'
    | 'type'
    | 'id'
    | 'required'
    | 'label'
    | 'error'
    | 'name'
    | 'multiline'
    | 'disabled'
  >,
  'id' | 'required' | 'error'
>

const TextField = ({
  onChange,
  multiline = false,
  value,
  type,
  id,
  name,
  required,
  label,
  error,
  disabled
}: TextFieldProps) => {
  const props = {
    margin: 'normal' as const,
    variant: 'outlined' as const,
    onChange: onChange,
    value: value,
    type: type,
    required: required,
    id: id,
    label: label,
    name: name,
    color: 'secondary' as const,
    error: error,
    disabled: disabled
  }
  return multiline ? (
    <MaterialTextField {...props} multiline fullWidth />
  ) : (
    <MaterialTextField {...props} fullWidth />
  )
}

export default TextField
