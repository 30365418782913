import { createContext } from 'react'
import { User, Project } from '../types'

type ProjectWizardContextValue = {
  wizardSteps: number
  currentStep: number
  validationErrors: { [key: string]: string[] }
  nextStep: () => void
  allowNext: boolean
  data: {
    user: undefined | User
    project: undefined | Partial<Project>
  }
  setters: {
    setAllowNext: (b: boolean) => void
    setCurrentStep: (i: number) => void
    setProject: (p: Partial<Project> | undefined) => void
  }
}

const throwNotImplementedError = (fn: string) => {
  throw {
    name: 'NotImplementedError',
    message: `${fn} should be overridden by a useState setter or similar in the component that uses the ProjectWizardContext Provider.`
  }
}

const ProjectWizardContext = createContext<ProjectWizardContextValue>({
  wizardSteps: 1,
  currentStep: 1,
  nextStep: () => {
    throwNotImplementedError('setAllowNext')
  },
  validationErrors: {},
  allowNext: false,
  data: {
    user: undefined,
    project: undefined
  },
  setters: {
    setAllowNext: () => {
      throwNotImplementedError('setAllowNext')
    },
    setCurrentStep: () => {
      throwNotImplementedError('setCurrentStep')
    },
    setProject: () => {
      throwNotImplementedError('setProject')
    }
  }
})

export default ProjectWizardContext
