import React, { useContext, useEffect, useState } from 'react'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import {
  ProjectTrigger,
  ProjectReceiver,
  RecommendationsTarget
} from '../../../types'
import { getRecordById } from '../../../helpers/utility'
import {
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  Input
} from '@mui/material'
import Fade from './Fade'
import Spinner from '../../Spinner'
import DataText from '../../typography/DataText'
import Label from '../../typography/Label'
import SelectInputLabel from '../../forms/SelectInputLabel'
import WizardFormItem from './WizardFormItem'

const ProjectDetails = ({ pageId }: { pageId: number }) => {
  const { currentStep, setters, data } = useContext(ProjectWizardContext)

  const [projectTriggerId, setProjectTriggerId] = useState<string>('')
  const [projectReceiverId, setProjectReceiverId] = useState<string>('')
  const [recommendationsTargetId, setRecommendationsTargetId] =
    useState<string>('')

  const [projectTriggers, projectTriggersLoading] = useFetchResource<
    IndexResponse<ProjectTrigger>
  >(
    `/partner_countries/${data.user?.partner_country_id}/project_triggers`,
    [data.user],
    !data.user
  )

  const [projectReceivers, projectReceiversLoading] = useFetchResource<
    IndexResponse<ProjectReceiver>
  >(
    `/partner_countries/${data.user?.partner_country_id}/project_receivers`,
    [data.user],
    !data.user
  )

  const [recommendationsTargets, recommendationsTargetsLoading] =
    useFetchResource<IndexResponse<RecommendationsTarget>>(
      `/partner_countries/${data.user?.partner_country_id}/recommendations_targets`,
      [data.user],
      !data.user
    )

  const setAllowNext = () => {
    setters.setAllowNext(
      projectTriggerId.length >= 1 &&
        projectReceiverId.length >= 1 &&
        recommendationsTargetId.length >= 1
    )
  }

  useEffect(() => {
    setProjectTriggerId(data.project?.project_trigger?.id.toString() || '')
    setProjectReceiverId(data.project?.project_receiver?.id.toString() || '')
    setRecommendationsTargetId(
      data.project?.recommendations_target?.id.toString() || ''
    )
  }, [])

  useEffect(() => {
    currentStep === pageId && setAllowNext()
  }, [currentStep])

  useEffect(() => {
    setters.setProject({
      ...data.project,
      project_trigger:
        getRecordById(projectTriggers?.data, projectTriggerId) ||
        data.project?.project_trigger,
      project_receiver:
        getRecordById(projectReceivers?.data, projectReceiverId) ||
        data.project?.project_receiver,
      recommendations_target:
        getRecordById(recommendationsTargets?.data, recommendationsTargetId) ||
        data.project?.recommendations_target
    })

    setAllowNext()
  }, [projectTriggerId, projectReceiverId, recommendationsTargetId])

  return (
    <>
      <Typography variant='h5' marginTop={3} display={'block'}>
        Project details
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} lg={4}>
          {projectTriggersLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'project_trigger'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <Box>
                    <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                      <SelectInputLabel id={'project-trigger-label'}>
                        Project Trigger *
                      </SelectInputLabel>
                      <Select
                        name={'project_trigger_id'}
                        error={error}
                        variant={'outlined'}
                        labelId={'project-trigger-label'}
                        value={projectTriggerId}
                        label={'Project Trigger'}
                        required={true}
                        onChange={(e) =>
                          setProjectTriggerId(e.target.value.toString())
                        }
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {projectTriggers.data.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.identifier}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Project Trigger</Label>
                    <DataText>
                      {data.project?.project_trigger?.identifier}
                    </DataText>
                    <Input
                      name={'project_trigger_id'}
                      value={data.project?.project_trigger?.id}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          {projectReceiversLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'project_receiver'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <Box>
                    <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                      <SelectInputLabel id={'project-receiver-label'}>
                        Project Receiver *
                      </SelectInputLabel>
                      <Select
                        name={'project_receiver_id'}
                        error={error}
                        variant={'outlined'}
                        labelId={'project-receiver-label'}
                        value={projectReceiverId}
                        label={'Project Receiver'}
                        required={true}
                        onChange={(e) =>
                          setProjectReceiverId(e.target.value.toString())
                        }
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {projectReceivers.data.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.identifier}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Project Receiver</Label>
                    <DataText>
                      {data.project?.project_receiver?.identifier}
                    </DataText>
                    <Input
                      name={'project_receiver_id'}
                      value={data.project?.project_receiver?.id}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12} lg={4}>
          {recommendationsTargetsLoading ? (
            <Spinner height={50} />
          ) : (
            <WizardFormItem
              pageId={pageId}
              attributeName={'recommendations_target'}
              renderFormField={(error) => (
                <Fade in={true}>
                  <Box>
                    <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                      <SelectInputLabel id={'recommendations-target-label'}>
                        Recommendations Target *
                      </SelectInputLabel>
                      <Select
                        name={'recommendations_target_id'}
                        error={error}
                        variant={'outlined'}
                        labelId={'recommendations-target-label'}
                        value={recommendationsTargetId}
                        label={'Project Receiver'}
                        required={true}
                        onChange={(e) =>
                          setRecommendationsTargetId(e.target.value.toString())
                        }
                      >
                        <MenuItem value={''}>None</MenuItem>
                        {recommendationsTargets.data.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.identifier}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Fade>
              )}
              renderTextField={() => (
                <Fade in={true}>
                  <Box>
                    <Label>Recommendations Target</Label>
                    <DataText>
                      {data.project?.recommendations_target?.identifier}
                    </DataText>
                    <Input
                      name={'recommendations_target_id'}
                      value={data.project?.recommendations_target?.id}
                      sx={{ display: 'none' }}
                    />
                  </Box>
                </Fade>
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ProjectDetails
