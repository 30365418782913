import { createContext } from 'react'
import { AuthContextValue } from '../types/User'

const AuthContext = createContext<AuthContextValue>({
  userSession: undefined,
  setUserSession: () => {
    throw {
      name: 'NotImplementedError',
      message:
        'setUserSession should be overridden by a useState setter or similar in the component that uses the AuthContext Provider.'
    }
  },
  userSessionFetched: false,
  setUserSessionFetched: () => {
    throw {
      name: 'NotImplementedError',
      message:
        'setUserSessionFetched should be overridden by a useState setter or similar in the component that uses the AuthContext Provider.'
    }
  }
})

export default AuthContext
