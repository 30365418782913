import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography
} from '@mui/material'
import Project from '../types/Project'
import { Link } from 'react-router-dom'
import Label from './typography/Label'
import DataText from './typography/DataText'

const ProjectCard = ({ project }: { project: Project }) => {
  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography sx={{ mb: 2 }} variant='h5'>
          {project.name}
        </Typography>

        <Label>Client Number:</Label>
        <DataText>{project.client_number}</DataText>

        <Label>Created at:</Label>
        <DataText>{project.created_at}</DataText>
      </CardContent>
      <CardActions>
        {project.state == 'submitted' ? (
          <Link to={`/projects/${project.id}`}>
            <Button size='small' variant='contained'>
              View
            </Button>
          </Link>
        ) : (
          <Link to={`/projects/${project.id}/edit`}>
            <Button size='small' variant='contained' color={'success'}>
              Edit
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  )
}

export default ProjectCard
