import React, { useState, useContext, FormEvent } from 'react'
import AuthContext from '../../../contexts/AuthContext'
import Form from '../../forms/Form'
import TextField from '../../forms/TextField'
import Button from '../../Button'
import {
  Box,
  Checkbox,
  Select,
  Typography,
  MenuItem,
  FormControl,
  Grid
} from '@mui/material'
import { SendRounded } from '@mui/icons-material'
import useFetchResource from '../../../hooks/useFetchResource'
import { IndexResponse } from '../../../types/Requests'
import { EnergyClass, Project, User } from '../../../types'
import Spinner from '../../Spinner'
import SelectInputLabel from '../../forms/SelectInputLabel'
import EnergySourceFields from './EnergySourceFields'
import { Id } from '../../../types/Generics'
import { toast } from 'react-toastify'
import SubmitErrors from '../../forms/SubmitErrors'
import { PartnerCountryContext } from '../../../contexts/PartnerCountryContext'
import RenovationStep from '../../../types/RenovationStep'

type NewPropTypes = {
  projectId: Project['id']
  renovationStep?: RenovationStep
  setShowForm: (show: boolean) => void
  createdRenovationSteps: number
  setCreatedRenovationSteps: (rerender: number) => void
}

const RenovationStepForm = ({
  projectId,
  renovationStep,
  setShowForm,
  createdRenovationSteps,
  setCreatedRenovationSteps
}: NewPropTypes) => {
  const { userSession } = useContext(AuthContext)
  const { modules } = useContext(PartnerCountryContext)
  const EnergyModule = modules.find((module) => module.identifier === 'energy')
  const StepCostModule = modules.find(
    (module) => module.identifier === 'step_cost'
  )
  const [targetYear, setTargetYear] = useState<number>(
    renovationStep ? renovationStep.target_year : new Date().getFullYear() + 1
  )
  const [asap, setAsap] = useState(renovationStep?.asap || false)
  const [energyClassId, setEnergyClassId] = useState<string>(
    renovationStep?.energy_class?.id?.toString() || ''
  )
  const [maintenanceCost, setMaintenanceCost] = useState(
    renovationStep?.maintenance_costs
      ? parseInt(renovationStep.maintenance_costs)
      : 0
  )
  const [renovationEnergyCost, setRenovationEnergyCost] = useState(
    renovationStep?.energy_costs ? parseInt(renovationStep.energy_costs) : 0
  )
  const [funding, setFunding] = useState(
    renovationStep?.funding ? parseInt(renovationStep.funding) : 0
  )
  const [energySourceIds, setEnergySourceIds] = useState<Id['id'][]>(
    renovationStep?.energy_sources?.map((source) => source.id) || []
  )
  const [finalEnergyDemand, setFinalEnergyDemand] = useState(
    renovationStep?.final_energy_demand
      ? parseInt(renovationStep.final_energy_demand)
      : 0
  )
  const [ghgEmissions, setGhgEmissions] = useState(
    renovationStep?.greenhouse_emissions
      ? parseInt(renovationStep.greenhouse_emissions)
      : 0
  )
  const [energyCosts, setEnergyCosts] = useState(
    renovationStep?.energy_energy_costs
      ? parseInt(renovationStep.energy_energy_costs)
      : 0
  )

  const [submitErrors, setSubmitErrors] = useState()

  const [user] = useFetchResource<User>(`/users/${userSession?.id}`)

  const [energyClasses, energyClassesLoading] = useFetchResource<
    IndexResponse<EnergyClass>
  >(
    `/partner_countries/${user?.partner_country_id}/energy_classes`,
    [user],
    !user
  )

  const submitForm = async (e: FormEvent) => {
    e.preventDefault()
    let costAttributesBody
    let energyAttributesBody
    let id
    const baseBody = {
      project_id: projectId,
      target_year: targetYear,
      asap: asap || null,
      energy_class_id: energyClassId
    }
    if (renovationStep?.id) id = { id: renovationStep.id }
    if (EnergyModule) {
      energyAttributesBody = {
        final_energy_demand: finalEnergyDemand,
        greenhouse_emissions: ghgEmissions,
        energy_energy_costs_in_cents: energyCosts * 100,
        energy_source_ids: energySourceIds
      }
    }
    if (StepCostModule) {
      costAttributesBody = {
        maintenance_costs_in_cents: maintenanceCost * 100,
        funding_in_cents: funding,
        energy_costs_in_cents: renovationEnergyCost * 100
      }
    }
    const path =
      renovationStep == undefined
        ? `user_projects/${projectId}/renovation_steps`
        : `renovation_steps/${renovationStep.id}`
    const method = renovationStep == undefined ? 'POST' : 'PATCH'
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/${path}`,
      {
        method: method,
        credentials: 'include',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify({
          ...id,
          ...baseBody,
          ...costAttributesBody,
          ...energyAttributesBody
        })
      }
    )

    const data = await res.json()

    if (res.ok) {
      toast(
        renovationStep
          ? 'Renovation step was successfully updated!'
          : 'Renovation step was successfully created!',
        { type: 'success' }
      )
      setCreatedRenovationSteps(createdRenovationSteps + 1)
      setShowForm(false)
    } else {
      setSubmitErrors(data.error_description)
    }
  }

  return (
    <Box marginTop={2} width={'100%'}>
      <SubmitErrors submitErrors={submitErrors} />
      <Form onSubmit={submitForm} title=''>
        {renovationStep?.base_step ? (
          <></>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={targetYear}
                  onChange={(e) => setTargetYear(Number(e.target.value))}
                  type='number'
                  label='Target year'
                  disabled={asap}
                  error={
                    submitErrors &&
                    Object.keys(submitErrors).includes('target_year')
                  }
                />
                <Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Checkbox
                      value={asap}
                      checked={asap}
                      onChange={(e) => setAsap(e.target.checked)}
                    />
                    <Typography>ASAP</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {energyClassesLoading ? (
                  <Spinner height={50} />
                ) : (
                  <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                    <SelectInputLabel id={'energy-class-label'}>
                      Energy class
                    </SelectInputLabel>
                    <Select
                      name={'energy_classes'}
                      variant={'outlined'}
                      labelId={'energy-class-label'}
                      value={energyClassId}
                      label={'Energy class'}
                      onChange={(e) =>
                        setEnergyClassId(e.target.value.toString())
                      }
                      error={
                        submitErrors &&
                        Object.keys(submitErrors).includes('energy_class')
                      }
                    >
                      <MenuItem value={''}>None</MenuItem>
                      {energyClasses.data.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.identifier}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {StepCostModule ? (
          !renovationStep?.base_step ? (
            <>
              <Typography variant='h6' marginBottom={1}>
                Costs
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={maintenanceCost}
                    id='maintenance_cost'
                    onChange={(e) => setMaintenanceCost(Number(e.target.value))}
                    type='number'
                    label='Maintenance cost (€)'
                    error={
                      submitErrors &&
                      Object.keys(submitErrors).includes(
                        'maintenance_cost_in_cents'
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={renovationEnergyCost}
                    onChange={(e) =>
                      setRenovationEnergyCost(Number(e.target.value))
                    }
                    id='energy_cost'
                    type='number'
                    label='Energy cost (€)'
                    error={
                      submitErrors &&
                      Object.keys(submitErrors).includes('energy_cost_in_cents')
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={funding}
                    onChange={(e) => setFunding(Number(e.target.value))}
                    id='funding'
                    type='number'
                    label='Funding (€)'
                    error={
                      submitErrors &&
                      Object.keys(submitErrors).includes('funding_in_cents')
                    }
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {EnergyModule ? (
          <>
            <Typography variant='h6' marginBottom={1}>
              Energy
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  value={finalEnergyDemand || 0}
                  onChange={(e) => setFinalEnergyDemand(Number(e.target.value))}
                  type='number'
                  id='final_energy_demand'
                  label={`Final energy demand ${EnergyModule.units['final_energy_demand']}`}
                  error={
                    submitErrors &&
                    Object.keys(submitErrors).includes('final_energy_demand')
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={ghgEmissions || 0}
                  onChange={(e) => setGhgEmissions(Number(e.target.value))}
                  type='number'
                  id='greenhouse_emissions'
                  label={`GHG emissions ${EnergyModule.units['greenhouse_emissions']}`}
                  error={
                    submitErrors &&
                    Object.keys(submitErrors).includes('greenhouse_emissions')
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={energyCosts}
                  onChange={(e) => setEnergyCosts(Number(e.target.value))}
                  type='number'
                  id='energy_energy_cost'
                  label={`Energy costs ${EnergyModule.units['energy_energy_costs_in_cents']}`}
                  error={
                    submitErrors &&
                    Object.keys(submitErrors).includes(
                      'energy_energy_costs_in_cents'
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <EnergySourceFields
          user={user}
          energySourceIds={energySourceIds}
          setEnergySourceIds={setEnergySourceIds}
        />
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant={'contained'}
            color={'success'}
            type='submit'
            icon={<SendRounded />}
          >
            Submit
          </Button>
        </Box>
      </Form>
    </Box>
  )
}

export default RenovationStepForm
