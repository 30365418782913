import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Container,
  MenuItem,
  Menu,
  IconButton,
  Typography,
  Toolbar,
  Box,
  AppBar
} from '@mui/material'
import { useState, useContext, useEffect } from 'react'
import AuthContext from '../contexts/AuthContext'
import Page from '../types/Page'
import UserMenu from './UserMenu'
import pages from '../config/pages'
import NavLink from './NavLink'

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [renderPages, setRenderPages] = useState<Page[]>([])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const { userSession } = useContext(AuthContext)

  useEffect(() => {
    setRenderPages(
      pages.filter((page) => {
        if (page.usersOnly) {
          if (userSession) {
            return page
          }
        } else {
          return page
        }
      })
    )
  }, [userSession])

  return (
    <AppBar position='fixed'>
      <Container fixed>
        <Toolbar disableGutters>
          <Link to='/'>
            <Box width={175} component='img' src='/images/ibroad-logo.png' />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'end'
            }}
          >
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {renderPages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <MenuLink to={page.path} key={page.title}>
                    <Typography textAlign='center'>{page.title}</Typography>
                  </MenuLink>
                </MenuItem>
              ))}
              <UserMenu />
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'end',
              alignItems: 'center',
              gap: 3
            }}
          >
            {renderPages.map((page) => (
              <NavLink to={page.path} key={page.title}>
                {page.title}
              </NavLink>
            ))}
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const MenuLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
`

export default Navigation
