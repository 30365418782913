import { Outlet } from 'react-router-dom'
import { PartnerCountryContext } from '../../contexts/PartnerCountryContext'
import { ProjectContext } from '../../contexts/ProjectContext'
import useFetchResource from '../../hooks/useFetchResource'
import { Module, User, Project } from '../../types'
import { useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'
import Spinner from '../Spinner'
import { IndexResponse } from '../../types/Requests'
import { useParams } from 'react-router'

export const ProjectsOutlet = () => {
  const { userSession } = useContext(AuthContext)
  const { projectId } = useParams()
  const [user, userLoading] = useFetchResource<User>(
    `/users/${userSession?.id}`,
    [userSession],
    !userSession
  )
  const [project, projectLoading] = useFetchResource<Project>(
    `/user_projects/${projectId}`,
    [projectId],
    !projectId
  )
  const partnerCountryId = user?.partner_country_id
  const [fetchedModules, modulesLoading] = useFetchResource<
    IndexResponse<Module>
  >(`/partner_countries/${partnerCountryId}/country_modules`, [user], !user)

  const partnerCountryContextValue = {
    partnerCountryId: partnerCountryId,
    modules: fetchedModules?.data
  }
  const projectValue: Project | undefined = projectId ? project : undefined
  const projectContextValue = {
    project: projectValue
  }

  return userLoading || modulesLoading || (projectId && projectLoading) ? (
    <Spinner />
  ) : (
    <PartnerCountryContext.Provider value={partnerCountryContextValue}>
      <ProjectContext.Provider value={projectContextValue}>
        <Outlet />
      </ProjectContext.Provider>
    </PartnerCountryContext.Provider>
  )
}
