import { BrowserRouter as Router } from 'react-router-dom'
import { useState } from 'react'
import { UserIdentifier } from './types/User'
import { CssBaseline } from '@mui/material'
import Navigation from './components/Navigation'
import Routes from './Routes'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './Theme'
import AuthContext from './contexts/AuthContext'
import { ToastContainer } from 'react-toastify'
import Authenticate from './components/Authenticate'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const [currentUserSession, setCurrentUserSession] = useState<
    UserIdentifier | undefined
  >()

  const [userSessionFetched, setUserSessionFetched] = useState<boolean>(false)

  const authProviderValue = {
    userSession: currentUserSession,
    setUserSession: setCurrentUserSession,
    userSessionFetched: userSessionFetched,
    setUserSessionFetched: setUserSessionFetched
  }

  return (
    <AuthContext.Provider value={authProviderValue}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Authenticate />
          <Navigation />
          <Routes />
        </Router>
        <ToastContainer style={{ top: '70px' }} />
      </ThemeProvider>
    </AuthContext.Provider>
  )
}

export default App
