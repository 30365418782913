function getRecordById<T extends { id: number }>(
  array: Array<T>,
  id: string
): T | undefined {
  return array?.find((e) => e.id === Number(id))
}

const capitalize = (string: string) => {
  const arr = string.split(' ')

  return arr.reduce(
    (accumulator, word) =>
      (accumulator += word.charAt(0).toUpperCase() + word.slice(1) + ' '),
    ''
  )
}

export { getRecordById, capitalize }
