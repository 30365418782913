import { Box, Typography, Button } from '@mui/material'
import { FetchError as FetchErrorType } from '../types/Requests'
import { Link } from 'react-router-dom'

const FetchError = ({ error }: { error: FetchErrorType }) => {
  return (
    <Box>
      <Typography variant='h2' sx={{ mb: 4 }}>
        {error.error}
      </Typography>
      {error.error_description.map((error, i) => (
        <Typography key={i} sx={{ mb: 4 }}>
          {error}
        </Typography>
      ))}

      <Link to={'/'}>
        <Button variant='contained'>Return home</Button>
      </Link>
    </Box>
  )
}

export default FetchError
