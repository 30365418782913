import {
  Button as MaterialButton,
  Typography,
  ButtonProps
} from '@mui/material'
import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'

type IconButtonProps = Pick<
  ButtonProps,
  'children' | 'onClick' | 'variant' | 'type' | 'color' | 'sx'
> & {
  icon?: ReactNode
}

const ResponsiveIconButtonStyled = styled(MaterialButton, {
  shouldForwardProp: (prop) => prop != 'breakpoint'
})<IconButtonProps>(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  minWidth: 'auto',

  [theme.breakpoints.down('sm')]: {
    minWidth: 32,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 4,
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '& .buttonText': {
      display: 'none'
    }
  }
}))

const Button = ({
  children,
  onClick,
  variant,
  icon,
  sx,
  color,
  type
}: IconButtonProps) => {
  return (
    <ResponsiveIconButtonStyled
      variant={variant}
      sx={sx || { margin: '1.5rem 0' }}
      onClick={onClick}
      startIcon={icon}
      color={color}
      type={type}
    >
      {icon ? (
        <Typography className='buttonText'>{children}</Typography>
      ) : (
        <Typography>{children}</Typography>
      )}
    </ResponsiveIconButtonStyled>
  )
}

export default Button
