import { useEffect, useContext } from 'react'
import AuthContext from '../contexts/AuthContext'
import { useNavigate } from 'react-router'

const Authenticate = () => {
  const { setUserSession, setUserSessionFetched } = useContext(AuthContext)

  const navigate = useNavigate()

  useEffect(() => {
    getUserByCookie()
  }, [])

  const getUserByCookie = async () => {
    const res = await fetch(
      `${process.env['REACT_APP_SERVER_ROOT_PATH']}/users/authenticate`,
      {
        credentials: 'include'
      }
    )

    const userSession = await res.json()

    if (res.status == 200) {
      setUserSession({ id: userSession.id, email: userSession.email })
    } else {
      setUserSession(undefined)
      navigate('/users/login')
    }

    setUserSessionFetched(true)
  }

  return null
}

export default Authenticate
