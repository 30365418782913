import { Button, Menu, MenuItem } from '@mui/material'
import { useState, useContext } from 'react'
import AuthContext from '../contexts/AuthContext'
import NavLink from './NavLink'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { userSession, setUserSession } = useContext(AuthContext)
  const navigate = useNavigate()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleProfile = () => {
    setAnchorEl(null)
    navigate(`/users/${userSession!.id}`)
  }

  const handleLogout = async () => {
    setAnchorEl(null)

    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/users/logout`,
      { credentials: 'include', method: 'POST' }
    )

    if (res.ok) {
      setUserSession(undefined)
      toast('Successfully logged out!', { type: 'success' })
      navigate('/users/login')
    } else {
      toast('Something went wrong. Please try again later.', { type: 'error' })
    }
  }

  return userSession ? (
    <>
      <Button
        id='account-button'
        variant={'contained'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {userSession.email}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button'
        }}
      >
        <MenuItem onClick={handleProfile}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  ) : (
    <NavLink to='/users/login'>Log in</NavLink>
  )
}

export default UserMenu
