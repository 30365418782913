import React from 'react'
import { Input } from '@mui/material'
import { theme } from '../../Theme'

const DisabledInput = ({
  value,
  name
}: {
  value: string | number | undefined
  name: string
}) => {
  return (
    <Input
      name={name}
      value={value}
      readOnly={true}
      disableUnderline={true}
      sx={{
        display: 'block',
        '& .MuiInputBase-input.Mui-readOnly': {
          WebkitTextFillColor: theme.palette.text.secondary
        }
      }}
    />
  )
}

export default DisabledInput
