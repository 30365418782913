import React, { useState, useContext, FormEvent, useEffect } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl
} from '@mui/material'
import Form from '../../../forms/Form'
import TextField from '../../../forms/TextField'
import useFetchResource from '../../../../hooks/useFetchResource'
import {
  MeasureCategory,
  MeasureType,
  RenovationMeasure
} from '../../../../types'
import { PartnerCountryContext } from '../../../../contexts/PartnerCountryContext'
import Spinner from '../../../Spinner'
import { IndexResponse } from '../../../../types/Requests'
import { CreationRenovationMeasure } from '../../../../types/RenovationMeasure'
import { toast } from 'react-toastify'
import SubmitErrors from '../../../forms/SubmitErrors'
import SelectInputLabel from '../../../forms/SelectInputLabel'

type FormPropType = {
  renovationStepId: number
  projectId: number

  setCreatedMeasures: ((a: number) => void) | undefined
  setShowForm: (show: boolean) => void
  createdMeasures: number | undefined
  renovationMeasure?: RenovationMeasure
}

export const RenovationMeasureForm = (props: FormPropType) => {
  const {
    renovationStepId,
    projectId,
    setCreatedMeasures,
    createdMeasures,
    setShowForm,
    renovationMeasure
  } = props
  const { partnerCountryId } = useContext(PartnerCountryContext)
  const [measureType, measureTypeLoading] = useFetchResource<MeasureType>(
    `/partner_countries/${partnerCountryId}/measure_types/${renovationMeasure?.measure_type_id}`,
    [renovationMeasure],
    !renovationMeasure
  )
  const [measureCategories, measureCategoriesLoading] =
    useFetchResource<IndexResponse<MeasureCategory>>(`/measure_categories`)
  const [measureCategoryId, setMeasureCategoryId] = useState<string>(
    measureType?.measure_category.id.toString()
  )
  const [measureTypes, measureTypesLoading] = useFetchResource<
    IndexResponse<MeasureType>
  >(
    `/partner_countries/${partnerCountryId}/measure_types?measureCategoryId=${measureCategoryId}&projectId=${projectId}&perPage=50`,
    [measureCategoryId],
    measureCategoryId === ''
  )

  useEffect(() => {
    setMeasureCategoryId(measureType?.measure_category.id.toString())
    setMeasureTypeId(measureType?.id.toString())
  }, [measureType])

  const [measureTypeId, setMeasureTypeId] = useState<string>(
    renovationMeasure?.measure_type_id.toString() || ''
  )
  const [specification, setSpecification] = useState<string>(
    renovationMeasure?.specification || ''
  )
  const [description, setDescription] = useState<string>(
    renovationMeasure?.description || ''
  )
  const [submitErrors, setSubmitErrors] = useState()

  const handleSubmitMeasure = async (e: FormEvent) => {
    e.preventDefault()
    const body: CreationRenovationMeasure = {
      specification: specification,
      description: description,
      measure_type_id: Number(measureTypeId)
    }
    let path = `renovation_steps/${renovationStepId}/renovation_measures`
    if (renovationMeasure) path = path + `/${renovationMeasure.id}`
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_ROOT_PATH}/${path}`,
      {
        method: renovationMeasure ? 'PATCH' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(body)
      }
    )

    const data = await res.json()

    if (res.ok) {
      toast(
        renovationMeasure
          ? 'Renovation measure was successfully updated!'
          : 'Renovation measure was successfully created!',
        { type: 'success' }
      )
      setCreatedMeasures && setCreatedMeasures(createdMeasures! + 1)
      setShowForm(false)
    } else {
      setSubmitErrors(data.error_description)
    }
  }

  const handleMeasureCategorySelect = (event: SelectChangeEvent) => {
    setMeasureTypeId('')
    setDescription('')
    setSpecification('')
    setMeasureCategoryId(event.target.value)
  }

  const handleMeasureTypeSelect = (event: SelectChangeEvent) => {
    const measureId = event.target.value
    setMeasureTypeId(measureId)
    const measure = measureTypes.data.find(
      (type) => Number(type.id) === Number(measureId)
    )
    setSpecification(measure?.specification || '')
    setDescription(measure?.description || '')
  }

  return renovationMeasure && measureTypeLoading ? (
    <Spinner />
  ) : (
    <Box>
      <SubmitErrors submitErrors={submitErrors} />
      <Form
        title={
          renovationMeasure
            ? 'Edit renovation measure'
            : 'New renovation measure'
        }
        onSubmit={handleSubmitMeasure}
      >
        {measureCategoriesLoading ? (
          <Spinner height={50} />
        ) : (
          <FormControl margin={'normal'}>
            <SelectInputLabel id={'measure-category-label'}>
              Measure category *
            </SelectInputLabel>
            <Select
              variant={'outlined'}
              labelId={'measure-category-label'}
              label={'Measure category'}
              onChange={handleMeasureCategorySelect}
              value={measureCategoryId}
            >
              <MenuItem selected> None </MenuItem>
              {measureCategories.data.map(
                (measureCategory: MeasureCategory) => (
                  <MenuItem
                    value={measureCategory.id}
                    key={`category_${measureCategory.id}`}
                  >
                    {measureCategory.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        )}

        {measureCategoryId ? (
          measureTypesLoading ? (
            <Spinner height={50} />
          ) : (
            <FormControl margin={'normal'}>
              <SelectInputLabel id={'measure-type-label'}>
                Measure type *
              </SelectInputLabel>
              <Select
                variant={'outlined'}
                labelId={'measure-type-label'}
                label={'Measure type'}
                onChange={handleMeasureTypeSelect}
                value={measureTypeId}
              >
                <MenuItem value={''} selected>
                  None
                </MenuItem>
                {measureTypes.data?.map((measureType: MeasureType) => (
                  <MenuItem
                    value={measureType.id}
                    key={`category_${measureType.id}`}
                  >
                    {measureType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : (
          <FormControl margin={'normal'}>
            <SelectInputLabel id={'measure-type-label'}>
              Measure type *
            </SelectInputLabel>
            <Select
              variant={'outlined'}
              labelId={'measure-type-label'}
              label={'Measure type'}
            >
              <MenuItem selected> None </MenuItem>
            </Select>
          </FormControl>
        )}
        <TextField
          multiline={true}
          label={'Description'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label={'Specification'}
          value={specification}
          onChange={(e) => setSpecification(e.target.value)}
        />
        <Button variant={'contained'} type='submit'>
          {renovationMeasure
            ? 'Update renovation measure'
            : 'Add renovation measure'}
        </Button>
      </Form>
    </Box>
  )
}
