import { ReactElement, useContext } from 'react'
import ProjectWizardContext from '../../../contexts/ProjectWizardContext'

type WizardFormItemPropType = {
  pageId: number
  attributeName: string
  renderFormField: (error: boolean) => ReactElement
  renderTextField: () => ReactElement
}

const WizardFormItem = (props: WizardFormItemPropType): ReactElement => {
  const { validationErrors, currentStep } = useContext(ProjectWizardContext)
  const { pageId, attributeName, renderFormField, renderTextField } = props
  const validationErrorPresent = () => {
    return Object.keys(validationErrors).includes(attributeName)
  }

  const displayFormField = () => {
    return currentStep === pageId || validationErrorPresent()
  }

  if (displayFormField()) {
    return renderFormField(validationErrorPresent())
  } else {
    return renderTextField()
  }
}
export default WizardFormItem
