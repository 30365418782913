import Page from '../../components/Page'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { User as UserType } from '../../types'
import { UserForm } from '../../components/users/UserForm'
import Button from '../../components/Button'
import DataText from '../../components/typography/DataText'
import Label from '../../components/typography/Label'
import { useParams } from 'react-router'
import { useState } from 'react'
import { CancelOutlined, Edit } from '@mui/icons-material'
import useFetchResource from '../../hooks/useFetchResource'
import Spinner from '../../components/Spinner'

export const User = () => {
  const { userId } = useParams()
  const [showForm, setShowForm] = useState<boolean>(false)
  const [rerenders, setRerenders] = useState<number>(1)
  const [user, userLoading] = useFetchResource<UserType>(`/users/${userId}`, [
    userId,
    rerenders
  ])

  return userLoading ? (
    <Spinner />
  ) : (
    <Page>
      <Typography marginBottom={4} variant={'h4'}>
        Profile
      </Typography>
      <Card variant='outlined'>
        <CardContent>
          <Box display={'flex'} justifyContent={'end'}>
            {showForm ? (
              <Button
                icon={<CancelOutlined />}
                onClick={() => setShowForm(false)}
                type={'button'}
                variant={'contained'}
                color={'warning'}
              >
                Cancel
              </Button>
            ) : (
              <Button
                type={'button'}
                variant={'contained'}
                color={'success'}
                icon={<Edit />}
                onClick={() => setShowForm(true)}
              >
                Edit
              </Button>
            )}
          </Box>
          {showForm ? (
            <UserForm
              user={user}
              setShowForm={setShowForm}
              rerender={rerenders}
              setRerender={setRerenders}
            />
          ) : (
            <Grid container>
              <Grid item xs={12} md={6}>
                <Label>First name</Label>
                <DataText>{user.first_name}</DataText>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>Last name</Label>
                <DataText>{user.last_name}</DataText>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>Email</Label>
                <DataText>{user.email}</DataText>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label>Phone number</Label>
                <DataText>{user.phone_number}</DataText>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Page>
  )
}
